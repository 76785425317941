import React, { useMemo, useState } from "react";
import {createForm, IFormFeedback, onFormInit, onFormSubmit} from "@formily/core";
import { useSelector } from "react-redux";
import { selectPreviewerValues } from "../../stores/questionnaireSlice";
import { FormProvider, Field } from "@formily/react";
import { Card } from "antd";
import { Radio, Checkbox } from "@formily/antd-v5";
import styles from "./QuestionnairePreviewer.module.css";
import { removeKeysFromObject } from "../Utils";
import { Input, FormItem } from '@formily/antd-v5'


import {
  WppTypography,
  WppTextareaInput,
  WppRadio,
  WppRadioGroup,
  WppCard,
  WppInput
} from "@wppopen/components-library-react";
import QuestionType from "../QuestionComponents/QuestionType";

interface Answer {
  [key: string]: string | number;
}

interface Question {
  answers: any[]; // JSON string of Answer[]
  question_text: string;
  question_code: string;
  type: string;
  sequence: string;
  validation: string;
}

interface Questions {
  [key: string]: Question;
}

const SingleGridPerRowAnswerType = ({ data }) => {
  const questions = data.filter((v) => v.ans_type === "ROW");
  const answerOptions = data.filter((v) => v.ans_type === "COLUMN");
  return (
    <>
      {questions.map((question) => {
        return (
          <WppCard>
            <div slot="header">
              <WppTypography>{question.ans_text}</WppTypography>
            </div>
            <WppRadioGroup style={{ display: "block" }}>
              {answerOptions.map((answer) => {
                return (
                  <div style={{ marginTop: "6px" }}>
                    <WppRadio
                      name={question.ans_text}
                      value={answer.ans_code}
                      labelConfig={{ text: answer.ans_text }}
                      required
                    />
                  </div>
                );
              })}
            </WppRadioGroup>
          </WppCard>
        );
      })}
    </>
  );
};

const QuestionnaireFormPreviewer: React.FC = () => {
  const builderFormValues = useSelector(selectPreviewerValues);
  const [formAttributesMap, setFormAttributesMap] = useState(null);

  const form = useMemo(
    () =>
      createForm({
        effects() {
          onFormSubmit(() => {  console.log(form.values) }),
          onFormInit(() => {
            const keysToRemove = [
              "survey_name",
              "description",
              "methodology",
              "type",
            ];
            const formAttributes = removeKeysFromObject(
              builderFormValues,
              keysToRemove
            );
            const QuestionFields = (questions: Questions) => {
              return Object.entries(questions)
                .sort(
                  (a, b) => parseInt(a[1].sequence) - parseInt(b[1].sequence)
                )
                .map(([key, question]) => {
                  const answers: Answer[] = question.answers;
                  return (
                    <div key={question.sequence}>
                      <Card
                        title={question.question_text}
                        key={question.sequence}
                        style={{ marginBottom: "20px", width: "45rem" }}
                      >
                        {question.type === QuestionType.Single ? (
                          <Field
                            name={key}
                            component={[
                              Radio.Group,
                              {
                                options: answers.map((answer) => ({
                                  label: answer[`${key}|ans_text`],
                                  value: answer[`${key}|ans_code`],
                                })),
                              },
                            ]}
                          />
                        ) : question.type === QuestionType.Multi ? (
                          <Field
                            name={key}
                            component={[
                              Checkbox.Group,
                              {
                                options: answers.map((answer) => ({
                                  label: answer[`${key}|ans_text`],
                                  value: answer[`${key}|ans_code`],
                                })),
                              },
                            ]}
                          />
                        ) : question.type === QuestionType.OpenText ? (
                          <Field name={key} component={[WppTextareaInput]} />
                        ) : question.type === QuestionType.OpenNumeric ? (
                                <Field name={key} decorator={[FormItem]}
                                       component={[WppInput,
                                           { onWppChange:(e)=>{
                                                   form.setValuesIn(key, e.target.value)
                                               },
                                               onWppBlur: (e)=>{
                                                   console.log(form.getValuesIn(key))
                                                   form.validate(key)
                                                       .then((result: IFormFeedback)=>{console.log(result)})
                                                       .catch((e: any)=>{console.log(e)})
                                               }}
                                       ]}
                                       validator={"number"}
                                         // (value) => {
                                         //   return validateNumericRange(value, question.validation)
                                         //  }
                                        />
                            ) : question.type === QuestionType.GridNumeric ? (
                                  answers.filter((v) => v.ans_type === "ROW").map((answer) => {
                                    return (
                                        <WppCard>
                                          <div slot="header">
                                            <WppTypography>{answer.ans_text}</WppTypography>
                                            </div>
                                            <Field name={key+answer.ans_code} decorator={[FormItem]}
                                                   component={[WppInput,
                                                     { onWppChange:(e)=>{
                                                         form.setValuesIn(key+answer.ans_code, e.target.value)
                                                       },
                                                       onWppBlur: (e)=>{
                                                         console.log(form.getValuesIn(key+answer.ans_code))
                                                         form.validate(key+answer.ans_code)
                                                             .then((result: IFormFeedback)=>{console.log(result)})
                                                             .catch((e: any)=>{console.log(e)})
                                                       }}
                                                   ]}
                                                   validator={"number"}
                                            />
                                        </WppCard>
                                    );
                                  })
                        ): (
                          <Field
                            name={key}
                            component={[SingleGridPerRowAnswerType, { data: answers }]}
                          />
                        )}
                      </Card>

                    </div>
                  );
                });
            };
            if (formAttributes) {
              const schema = QuestionFields(formAttributes);
              setFormAttributesMap(schema);
            }
          });
        },
      }),
    [builderFormValues]
  );

  const QuestionnaireInfo = () => {
    let questionnaireInfo = {
      title: "No questionnaire created yet",
      description: "",
      methodology: "",
      type: "",
    };
    if (builderFormValues) {
      questionnaireInfo = {
        title: builderFormValues.survey_name,
        description: builderFormValues.description,
        methodology: builderFormValues.methodology,
        type: builderFormValues.type,
      };
    }
    return (
      <WppTypography>
        <h2>{questionnaireInfo.title}</h2>
        <h4 className={styles["questionnaire-previewer-description"]}>
          {questionnaireInfo.description}
        </h4>
        <h4 className={styles["questionnaire-previewer-text"]}>
          Methodology: {questionnaireInfo.methodology}
        </h4>
        <h4 className={styles["questionnaire-previewer-text"]}>
          Type: {questionnaireInfo.type}
        </h4>
      </WppTypography>
    );
  };

  return (
    <div className={styles["questionnaire-form-previewer"]}>
      <QuestionnaireInfo />
      <FormProvider form={form}>{formAttributesMap}
      </FormProvider>

    </div>
  );
};

export default QuestionnaireFormPreviewer;
