import {useEffect, useState} from "react";
import {
    WppStepper,
    WppStep,
    WppTopbar,
    WppButton,
    WppTypography,
    WppCheckbox,
} from "@wppopen/components-library-react";
import {useNavigate} from "react-router-dom";
import styles from "./new-metadata-ingestion-workflow.module.scss";
import React from "react";
import {AddSurveyStep} from "./AddSurveyStep";
import {STEP_CONTENT} from "./MetaDagConstants";
import {DownloadSpecFileStep} from "./DownloadSpecFileStep";
import {RenameDataDirectoryStep} from "./RenameDataDirectoryStep";
import {RenameSpecFileStep} from "./RenameSpecFileStep";
import {TriggerDagStep} from "../Common/TriggerDagStep";
import {InstructionStep} from "../Common/InstructionStep";
import {NetDagConstants} from "../NetVariableIngestion/NetDagConstants";
import {RawMetadataDiffStep} from "./RawMetadataDiffStep";
import {AutocompleteDefaultOption} from "@wppopen/components-library";
import { UploadFilesStep } from "../Common/UploadFilesStep";


const WORKFLOW_STEPS = 9;

const getStepInitialData = (noOfSteps: number) => {
    return Array.from({length: noOfSteps}, (_, index) => ({
        [index]: {checked: false, error: false},
    })).reduce((p, c) => {
        return {...p, ...c};
    }, {});
};

export const MetadataIngestionWorkflow = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [highestStep, setHighestStep] = useState(1);
    const [pageStatus, setPageStatus] = useState(
        getStepInitialData(WORKFLOW_STEPS)
    );

    const [survey, setSurvey] = useState<AutocompleteDefaultOption[]>([]);
    const [surveyData, setsurveyData] = useState({WAVE_ID: 0, YEAR: 0});
    const [shouldDisableStepper, setShouldDisableStepper] = useState(false);
    const gcsFileLocation = NetDagConstants.netConfigUploadUrl.substring(NetDagConstants.netConfigUploadUrl.indexOf('pipelines'));
    const [areDicFilesUploadedSuccessfully, setAreDicFilesUploadedSuccessfully] =
        useState(false);
    const [
        areSpecFilesUploadedSuccessfully,
        setAreSpecFilesUploadedSuccessfully,
    ] = useState(false);
    const onDagSuccess = () => {
        setShouldDisableStepper(true)
    };

    useEffect(() => {
        if (!areDicFilesUploadedSuccessfully && currentStep == WORKFLOW_STEPS - 2) {
            setShouldDisableStepper(true);
        } else {
            setShouldDisableStepper(false);
        }
    }, [areDicFilesUploadedSuccessfully, currentStep]);

    useEffect(() => {
        if (areDicFilesUploadedSuccessfully) {
            if (
                !areSpecFilesUploadedSuccessfully &&
                currentStep == WORKFLOW_STEPS - 1
            ) {
                setShouldDisableStepper(true);
            } else {
                setShouldDisableStepper(false);
            }
        }
    }, [areSpecFilesUploadedSuccessfully, currentStep]);

    useEffect(()=>{
        if (survey.length === 0 && currentStep === 2) {
           setShouldDisableStepper(true);
        }
        else{
            setShouldDisableStepper(false);
        }
    },[survey, currentStep]);

    const handleNextStep = () => {
        if (currentStep === WORKFLOW_STEPS) {
            return;
        }
        const step = currentStep + 1;
        if (currentStep === 1 && survey.length > 0) {
            setPageStatus({
                ...pageStatus,
                [currentStep]: {checked: true, error: false},
            });
            setCurrentStep(step);
            if (step > highestStep) {
                setHighestStep(step);
            }
            return;
        }

        if (
            pageStatus[currentStep].checked ||
            (areSpecFilesUploadedSuccessfully &&
                currentStep === WORKFLOW_STEPS - 1) ||
            (areDicFilesUploadedSuccessfully && currentStep === WORKFLOW_STEPS - 2)
        ) {
            setCurrentStep(step);
            if (step > highestStep) {
                setHighestStep(step);
            }
            setPageStatus({
                ...pageStatus,
                [currentStep]: {checked: true, error: false},
            });
        } else {
            setPageStatus({
                ...pageStatus,
                [currentStep]: {...pageStatus[currentStep], error: true},
            });
        }
    };
    const handlePreviousStep = () => {
        if (currentStep === 1) {
            return;
        }
        setCurrentStep(currentStep - 1);
    };

    const handleCheckboxStatus = (checked: boolean, index: number) => {
        setPageStatus({
            ...pageStatus,
            [index]: {error: false, checked},
        });
    };

    const handleSurveyChange = (selectedSurvey: AutocompleteDefaultOption[]) => {
        setCurrentStep(2);
        setHighestStep(2);
        setPageStatus(getStepInitialData(WORKFLOW_STEPS));
        setAreDicFilesUploadedSuccessfully(false);
        setAreSpecFilesUploadedSuccessfully(false);
        console.log("selected survey is ", selectedSurvey);
        setSurvey(selectedSurvey);
        const storedData = localStorage.getItem('surveyWaveData');
        if (storedData) {
            var tempData = JSON.parse(storedData);
        }
        tempData = tempData.filter(item => item.SURVEY_NAME === selectedSurvey)[0];
        setsurveyData(tempData);
        setPageStatus({
            ...pageStatus,
            [currentStep]: {checked: true, error: false},
        });

    };

    const getSelectedPage = () => {
        switch (currentStep) {
            case 1:
                return (
                    <InstructionStep
                        checked={pageStatus[currentStep].checked}
                        onChange={(checked) => handleCheckboxStatus(checked, currentStep)}
                        title="Please read the instructions below to proceed with the Metadata Run:"
                        context={["This is used to upload raw variable metadata to snowflake DB.",
                            "The latest uploaded set is considered as newer version amd will take precedence over previous ones.",
                        ]}
                        checkboxText="I have read and understood the instructions."
                        links={[{
                            label: "Confluence Wiki",
                            link: STEP_CONTENT.metadataIngestionWikiLink,
                            context: "Find more details here"
                        }]}
                    />
                );
            case 2:
                return (
                    <AddSurveyStep value={survey} onValueChange={handleSurveyChange}/>
                );
            case 3:
                return (
                    <DownloadSpecFileStep
                        checked={pageStatus[currentStep].checked}
                        onChange={(checked) => handleCheckboxStatus(checked, currentStep)}
                    />
                );
            case 4:
                return (
                    <RenameDataDirectoryStep
                        checked={pageStatus[currentStep].checked}
                        onChange={(checked) => handleCheckboxStatus(checked, currentStep)}
                    />
                );
            case 5:
                return (
                    <RenameSpecFileStep
                        checked={pageStatus[currentStep].checked}
                        onChange={(checked) => handleCheckboxStatus(checked, currentStep)}
                    />
                );
            case 6:
                return (
                    <UploadFilesStep
                        checked={pageStatus[currentStep].checked}
                        onChange={(checked) => handleCheckboxStatus(checked, currentStep)}
                        title="  Please upload dictionary and spec file:"
                        links={[
                           
                            {
                                label: "GCS Location",
                                link: STEP_CONTENT.metadataUploadUrl,
                                context: " Please upload dictionary and spec file in the below link",
                        
                            }

                            
                        ]}
                        checkboxText="I have uploaded the config files."
                    />
                );
            case 7:
                const gcsFileLocation = NetDagConstants.netConfigUploadUrl.substring(NetDagConstants.netConfigUploadUrl.indexOf("pipelines"));

                return (
                    <RawMetadataDiffStep
                        fileType="DIC"
                        survey={survey[0].label}
                        onSuccess={setAreDicFilesUploadedSuccessfully}/>
                );
            case 8:

                return (
                    <RawMetadataDiffStep
                        fileType="SPEC"
                        survey={survey[0].label}
                        onSuccess={setAreSpecFilesUploadedSuccessfully}/>
                );
            case 9:
                return (
                    <TriggerDagStep
                        onDagSuccess={onDagSuccess}
                        configName={STEP_CONTENT.TriggerMetadataIngestionDAG}
                        survey={survey[0].label}
                    />
                );
        }
        return (
            <div className={styles.page}>
                <h3>Page {currentStep}</h3>
            </div>
        );
    };

    const handleStepClick = (event) => {
        if (shouldDisableStepper) {
            return;
        }

        const step = event.detail.index;
        if (step <= highestStep) {
            setCurrentStep(step);
        }
    };

    const navigateToMIHome = () => {
        navigate("/data-processing/metadata-ingestion");
    };

    return (
        <div>
            <WppTopbar navigation={[]} style={{margin: "1rem"}}>
                <div slot="app">
                    <WppTypography type="l-midi" style={{width: "20rem"}}>
                        New Metadata Ingestion
                    </WppTypography>
                </div>
            </WppTopbar>
            <div className={styles.main}>
                <div className={styles.wrapper}>
                    <div className={styles.stepper}>
                        <WppStepper activeStep={currentStep}>
                            <WppStep
                                completed={highestStep > 1}
                                error={pageStatus[1].error}
                                onWppStepChange={handleStepClick}
                            >
                                <p className={styles.text} slot="label">
                                    Instructions
                                </p>
                            </WppStep>
                            <WppStep
                                completed={highestStep > 2}
                                error={pageStatus[2].error}
                                onWppStepChange={handleStepClick}
                            >
                                <p slot="label" className={styles.text}>
                                    Survey
                                </p>
                            </WppStep>
                            <WppStep
                                completed={highestStep > 3}
                                error={pageStatus[3].error}
                                onWppStepChange={handleStepClick}
                            >
                                <p slot="label" className={styles.text}>
                                    Download Files
                                </p>
                            </WppStep>
                            <WppStep
                                completed={highestStep > 4}
                                error={pageStatus[4].error}
                                onWppStepChange={handleStepClick}
                            >
                                <p slot="label" className={styles.text}>
                                    Prepare Dictionary File
                                </p>
                            </WppStep>

                            <WppStep
                                completed={highestStep > 5}
                                error={pageStatus[5].error}
                                onWppStepChange={handleStepClick}
                            >
                                <p slot="label" className={styles.text}>
                                    Prepare Specification File
                                </p>
                            </WppStep>

                            <WppStep
                                completed={highestStep > 6}
                                onWppStepChange={handleStepClick}
                                error={pageStatus[6].error}
                                aria-errormessage="Select checkbox"
                            >
                                <p className={styles.text} slot="label">
                                    Upload Files
                                </p>
                            </WppStep>

                            <WppStep
                                completed={highestStep > 7}
                                onWppStepChange={handleStepClick}
                            >
                                <p className={styles.text} slot="label">
                                    Compare Dictionary Files
                                </p>
                            </WppStep>

                            <WppStep
                                completed={highestStep > 8}
                                onWppStepChange={handleStepClick}
                            >
                                <p className={styles.text} slot="label">
                                    Compare Specification Files
                                </p>
                            </WppStep>

                            <WppStep
                                completed={highestStep > 9}
                                onWppStepChange={handleStepClick}
                            >
                                <p className={styles.text} slot="label">
                                    Trigger
                                </p>
                            </WppStep>
                        </WppStepper>
                    </div>
                    <div className={styles.pages}>
                        <div className={styles.innerPage}>
                            <div className={styles.page}>{getSelectedPage()}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.buttons}>
                    {shouldDisableStepper &&
                    areDicFilesUploadedSuccessfully &&
                    areSpecFilesUploadedSuccessfully ? (
                        <WppButton variant="secondary" onClick={navigateToMIHome}>
                            Go To Metadata Ingestion Home
                        </WppButton>
                    ) : (
                        <>
                            <WppButton variant="secondary" onClick={handlePreviousStep}>
                                Previous Step
                            </WppButton>
                            <WppButton
                                onClick={handleNextStep}
                                className={styles.button}
                                disabled={shouldDisableStepper}
                                title={
                                    shouldDisableStepper
                                        ? "Please check the current step for status"
                                        : ""
                                }
                            >
                                Next Step
                            </WppButton>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
