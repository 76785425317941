import React from "react";
import {
  WppButton,
  WppTypography,
} from "@wppopen/components-library-react";

import styles from "./dashboard.module.css";
import Dashboard from "./Dashboard";

const HomePage = () => {
  return (
    <>
      <div className={styles["dashboard-welcome-section"]}>
        <WppTypography type="5xl-display">
          Discover the Power of Audience Origin Data
        </WppTypography>
        <div>
          <WppButton
            onClick={() => {
              const main = document.getElementById("main");
              main.scrollTop = main.scrollHeight;
            }}
          >
            Explore
          </WppButton>
        </div>
      </div>
      <section
        id="dashboard-explore-section"
        className={styles["dashboard-exlore-section"]}
      >
        <Dashboard />
      </section>
    </>
  );
};

export default HomePage;
