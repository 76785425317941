import React, {useState} from "react";
import {
    WppStepper,
    WppStep,
    WppTopbar,
    WppButton,
    WppTypography,
} from "@wppopen/components-library-react";
import {useNavigate} from "react-router-dom";
import styles from "../Dashboard/data-ingestion-workflow.module.scss";

import {InstructionStep} from "../Common/InstructionStep";
import {UploadFilesStep} from "../Common/UploadFilesStep";
import {TriggerDagStep} from "../Common/TriggerDagStep";
import {NetDagConstants} from "./NetDagConstants";
import {SurveySelectStep} from "../Common/SurveySelectStep";
import {MetadataDiffStep} from "../Common/MetadataDiffStep";

const WORKFLOW_STEPS = 5;

export const NetMetadataIngestionWorkflow = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [highestStep, setHighestStep] = useState(1);
    const [isDagSuccess, setIsDagSuccess] = useState(false);
    const [instructionStepChecked, setInstructionStepChecked] =
        useState(false);
    const [
        isInstructionStepPristine,
        setIsInstructionStepPristine,
    ] = useState(true);
    var year = 21;
    const [surveyData, setsurveyData] = useState({WAVE_ID: 0, YEAR: 0});
    const [uploadFilesStepChecked, setUploadFilesStepChecked] = useState(false);
    const [isUploadFilesStepPristine, setUploadFilesStepPristine] =
        useState(true);
    const getStepInitialData = (noOfSteps: number) => {
        return Array.from({length: noOfSteps}, (_, index) => ({
            [index]: {checked: false, error: false},
        })).reduce((p, c) => {
            return {...p, ...c};
        }, {});
    };

    const [pageStatus, setPageStatus] = useState(
        getStepInitialData(WORKFLOW_STEPS)
    );
    const [survey, setSurvey] = useState("");
    const [areDicFilesUploadedSuccessfully, setAreDicFilesUploadedSuccessfully] =
        useState(false);
    const [
        areSpecFilesUploadedSuccessfully,
        setAreSpecFilesUploadedSuccessfully,
    ] = useState(false);

    const handleSurveyChange = (selectedSurvey: string) => {
        setCurrentStep(2);
        setHighestStep(2);
        setPageStatus(getStepInitialData(WORKFLOW_STEPS));
        setAreDicFilesUploadedSuccessfully(false);
        setAreSpecFilesUploadedSuccessfully(false);
        setSurvey(selectedSurvey);
        const storedData = localStorage.getItem('surveyWaveData');
        if (storedData) {
            var tempData = JSON.parse(storedData);
        }
        tempData = tempData.filter(item => item.SURVEY_NAME === selectedSurvey)[0];
        setsurveyData(tempData);
        setPageStatus({
            ...pageStatus,
            [currentStep]: {checked: true, error: false},
        });

    };


    const handleNextStep = () => {
        if (currentStep === WORKFLOW_STEPS) {
            return;
        }
        if (currentStep === 1) {
            setIsInstructionStepPristine(false);

            if (!instructionStepChecked) {
                return;
            }
        }
        if (currentStep == 2) {
            if ((survey.trim().length == 0)) {
                return;
            }

        }
        if (currentStep === 3) {
            setUploadFilesStepPristine(false);

            if (!uploadFilesStepChecked) {
                return;
            }
        }

        const step = currentStep + 1;
        setCurrentStep(step);
        if (step > highestStep) {
            setHighestStep(step);
        }
    };
    const handlePreviousStep = () => {
        if (currentStep === 1) {
            return;
        }

        setCurrentStep(currentStep - 1);
    };

    const onDagSuccess = () => {
        setIsDagSuccess(true);
    };

    const getSelectedPage = () => {
        switch (currentStep) {
            case 1:
                return (
                    <InstructionStep
                        checked={instructionStepChecked}
                        onChange={setInstructionStepChecked}
                        title="Please read the instructions below to proceed with the Net Calculation Metadata Run:"
                        context={["This is used to upload net variable metadata to snowflake DB.",
                            "Please use Net Test run to test and confirm the content of yml files before uploading here.",
                            "A complete set of yml files need to be specified for a given year/wave.",
                            "The latest uploaded set is considered as newer version amd will take precedence over previous ones.",
                        ]}
                        checkboxText="I have read and understood the instructions."
                        links={[]}
                    />
                );
            case 2:
                return (
                    <SurveySelectStep value={survey} onValueChange={handleSurveyChange}/>
                )
            case 3:
                return (
                    <UploadFilesStep
                        checked={uploadFilesStepChecked}
                        onChange={setUploadFilesStepChecked}
                        title="Upload Net YML Config Files To Snowflake DB:"
                        links={[
                            {
                                label: "Confluence Wiki",
                                link: NetDagConstants.netMetaRunWikiLink,
                                context: "Find detailed instruction for net metadata run here",
                            },
                            {
                                label: "GCS Location",
                                link: NetDagConstants.netConfigUploadUrl,
                                context: "Clean folder and upload prepared yml files here",
                            }
                        ]}
                        checkboxText="I have uploaded the config files."
                    />
                );
            case 4 :
                const gcsFileLocation = NetDagConstants.netConfigUploadUrl.substring(NetDagConstants.netConfigUploadUrl.indexOf('audience'));
                return (
                    
                    <MetadataDiffStep
                        gcsFileLocation={gcsFileLocation}
                        wave={surveyData.WAVE_ID.toString()}
                        year={surveyData.YEAR}
                        onSuccess={setAreDicFilesUploadedSuccessfully}
                    />
                );
            case 5:
                return (
                    <TriggerDagStep
                        onDagSuccess={onDagSuccess}
                        configName={NetDagConstants.TriggerNetMetadataIngestionDAG}
                        survey={survey}
                    />
                );

        }
        return (
            <div>
                <h3>Page {currentStep}</h3>
            </div>
        );
    };

    const handleStepClick = (event) => {
        if (isDagSuccess) {
            return;
        }

        const step = event.detail.index;
        if (step <= highestStep) {
            setCurrentStep(step);
        }
    };

    const navigateToMIHome = () => {
        navigate("/data-processing/net-metadata-ingestion");
    };

    return (
        <div>
            <WppTopbar navigation={[]} style={{margin: "1rem"}}>
                <div slot="app">
                    <WppTypography type="l-midi" style={{width: "20rem"}}>
                        New Net Metadata Ingestion
                    </WppTypography>
                </div>
            </WppTopbar>
            <div
                style={{
                    display: "flex",
                    margin: "2rem",
                }}
            >
                <WppStepper activeStep={currentStep} style={{width: "19rem"}}>
                    <WppStep
                        onWppStepChange={handleStepClick}
                        error={
                            !instructionStepChecked &&
                            !isInstructionStepPristine
                        }
                    >
                        <p slot="label">Instructions</p>
                    </WppStep>
                    <WppStep

                        error={pageStatus[2].error}
                        onWppStepChange={handleStepClick}
                    >
                        <p slot="label" className={styles.text}>
                            Survey
                        </p>
                    </WppStep>
                    <WppStep
                        onWppStepChange={handleStepClick}
                        error={!uploadFilesStepChecked && !isUploadFilesStepPristine}
                    >
                        {" "}
                        <p slot="label">Upload Files</p>
                    </WppStep>
                    <WppStep

                        onWppStepChange={handleStepClick}
                    >
                        <p className={styles.text} slot="label">
                            Compare config Files
                        </p>
                    </WppStep>

                    <WppStep onWppStepChange={handleStepClick}>
                        <p slot="label">Trigger DAG</p>
                    </WppStep>

                </WppStepper>
                <div className={styles["stepper-container"]}>{getSelectedPage()}</div>
            </div>

            <div
                style={{
                    display: "flex",
                    margin: "2rem",
                    justifyContent: "flex-end",
                }}
            >
                {isDagSuccess ? (
                    <WppButton variant="secondary" onClick={navigateToMIHome}>
                       Go To Net Meta Home
                    </WppButton>
                ) : (
                    <>
                        <WppButton
                            variant="secondary"
                            onClick={handlePreviousStep}
                            disabled={isDagSuccess}
                            style={{marginRight: "1rem"}}
                        >
                            Previous Step
                        </WppButton>
                        <WppButton onClick={handleNextStep} disabled={isDagSuccess}>
                            Next Step
                        </WppButton>
                    </>
                )}
            </div>
        </div>
    );
};
