import React, { useState, useEffect } from "react";
import { WppAccordion, WppSpinner, WppTypography } from "@wppopen/components-library-react";
import { MetadataDiffGrid } from "../MetadataIngestion/MetadataDiffGrid";
import styles from "../MetadataIngestion/new-metadata-ingestion-workflow.module.scss";
import { AG_GRID_CELL_STYLE } from "../../../constants/ag-grid-defaults";
import { useQuery } from "@tanstack/react-query";
import { AudienceOriginRequest, EndPoints } from "../../../api/audience-origin";
import { MetadataDiffSheetItemBase } from "../../../types";
import { IngestionGrid } from "../IngestionGrid/IngestionGrid";

interface DiffItem {
    file: string;
    op: "add" | "remove" | "replace";
    path: string;
    newValue?: string;
    oldValue?: string;
}

interface YamlDiffData {
    content?: DiffItem[];
    files?: string[];
    code?: string;
    message?: string[]; 
}

interface AdditionalYamldiffStepProps {
    gcsFileLocation2: string;
    survey: string;
    onSuccess: (success: boolean) => void;
}

const columnDefs = [
    { headerName: "File", field: "file", sortable: true, cellStyle: AG_GRID_CELL_STYLE, maxWidth: 200 },
    { headerName: "Op", field: "op", sortable: true, cellStyle: AG_GRID_CELL_STYLE },
    { headerName: "Path", field: "path", sortable: true, cellStyle: AG_GRID_CELL_STYLE },
];

const modifyColumnDefs = [
    ...columnDefs,
    { headerName: "NewValue", field: "newValue", sortable: true, cellStyle: AG_GRID_CELL_STYLE },
    { headerName: "OldValue", field: "oldValue", sortable: true, cellStyle: AG_GRID_CELL_STYLE },
];

const adaptDiffItem = (item: DiffItem): MetadataDiffSheetItemBase => ({
    ...item,
    Role: "Data", 
    "Column Width": "auto", 
    Alignment: "left", 
    Variable: item.path, 
    "Write Format": "Default",  
    "Measurement Level": "Level 1",  
    Position: "Top",  
    "Print Format": "Standard",
});

export const AdditionalYamldiffStep: React.FC<AdditionalYamldiffStepProps> = ({ 
    gcsFileLocation2,
    survey, 
    onSuccess 
}) => {
    const { status, error, data, isFetching } = useQuery(
        ["combinedMetadataDiff", gcsFileLocation2, survey],
        async () => {
            const response = await AudienceOriginRequest.get(`${EndPoints.NetyamldataDiff}?fileName=${gcsFileLocation2}&survey=${survey}`);
            return response.data;
        },
        { retry: false }
    );
    
    const [isAddedOpen, setIsAddedOpen] = useState(false);
    const [isModifiedOpen, setIsModifiedOpen] = useState(false);
    const [isDeletedOpen, setIsDeletedOpen] = useState(false);
    const [diffData, setDiffData] = useState<YamlDiffData | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const initializeData = () => {
            try {
                if (data?.code && data?.message) {
                    const errorMessage = data.message.join(" ");
                    console.error("API Error: ", errorMessage);
                    setIsLoading(false);
                    onSuccess(false);
                    return;
                }

                setDiffData(data);
                setIsLoading(false);
                onSuccess(true);
            } catch (err) {
                console.error("Error initializing data:", err);
                setIsLoading(false);
                onSuccess(false);
            }
        };

        if (data) {
            initializeData();
        }
    }, [data, onSuccess]);

    const filterData = (opType: "add" | "remove" | "replace"): DiffItem[] => {
        if (!diffData?.content) return [];
        return diffData.content.filter(item => item.op === opType);
    };
console.log(filterData);
    const addData = filterData("add");
    const deleteData = filterData("remove");
    const modifyData = filterData("replace");

    const gridTemplate = (
        label: string, 
        gridData: DiffItem[], 
        columnDefs: any[]
    ) => {
        const handleAccordionChange = (e: CustomEvent) => {
            const isExpanded = e.detail.expanded;
            if (label.includes("Added")) setIsAddedOpen(isExpanded);
            if (label.includes("Modified")) setIsModifiedOpen(isExpanded);
            if (label.includes("Deleted")) setIsDeletedOpen(isExpanded);
        };

        const adaptedData = gridData.map(adaptDiffItem);

        return (
            <WppAccordion 
                size="l"
                expanded={label.includes("Added") ? isAddedOpen : 
                         label.includes("Modified") ? isModifiedOpen : 
                         label.includes("Deleted") ? isDeletedOpen : false}
                onWppChange={handleAccordionChange}
            >
                <WppTypography type="l-body" slot="header">{label}</WppTypography>
                <div style={{ width: '100%', height: '100%' }}>
                    {gridData.length > 0 ? (
                        <MetadataDiffGrid data={adaptedData} columns={columnDefs} />
                    ) : (
                        <WppTypography type="l-body">No Data</WppTypography>
                    )}
                </div>
            </WppAccordion>
        );
    };

    const getFileName = (file: string): string => {
        return file.split("/").pop() || "";
    };

    const getErrorMessage = (error) => {
        return error?.secondError?.response?.data?.message;
    };

    return (
        <div style={{ overflow: "scroll", height: "100%", width: "100%" }}>
            {isFetching && <WppSpinner size="m" />}
            {error && (
                <WppTypography>
                    <strong>Warning: </strong>
                    {getErrorMessage(error)}
                </WppTypography>
            )}
            {data && !error && (
                <div style={{ display: 'block', overflow: "scroll", height: "100%", width: "100%" }}>
                    {isLoading ? (
                        <div className={styles.centerContent}>
                            <WppSpinner size="m" />
                        </div>
                    ) : (
                        <div>
                            <div style={{ margin: "2rem" }}>
                                {diffData?.files?.map((file, index) => (
                                    <div key={index} style={{ marginBottom: "0.5rem" }}>
                                        <WppTypography type="l-midi">
                                            {index === 0 ? "Old File (Processed):" : "New File (Pre-Processed):"}
                                        </WppTypography>
                                        <WppTypography type="l-body" className={styles.highlightText}>
                                            {getFileName(file)}
                                        </WppTypography>
                                    </div>
                                ))}
                            </div>

                            <div style={{ marginTop: "1rem", marginLeft: "2rem", marginRight: "3rem" }}>
                                
                                <WppAccordion  >
                                        <WppTypography type="m-strong" slot="header">
                                            Added ({addData.length})
                                        </WppTypography>
                                        {addData.length > 0 && (
                                            <IngestionGrid 
                                                // style={{right:"200px"}}
                                                columns={columnDefs} 
                                                data={addData} 
                                                status={status} 
                                            />
                                        )}
                                    </WppAccordion>

                                <WppAccordion  >
                                        <WppTypography type="m-strong" slot="header">
                                            Modified ({modifyData.length})
                                        </WppTypography>
                                        {modifyData.length > 0 && (
                                            <IngestionGrid 
                                                columns={modifyColumnDefs} 
                                                data={modifyData} 
                                                status={status} 
                                            />
                                        )}
                                    </WppAccordion>
                             
                                    <WppAccordion  >
                                        <WppTypography type="m-strong" slot="header">
                                            Deleted ({deleteData.length})
                                        </WppTypography>
                                        {deleteData.length > 0 && (
                                            <IngestionGrid 
                                                columns={columnDefs} 
                                                data={deleteData} 
                                                status={status} 
                                            />
                                        )}
                                    </WppAccordion>
                                </div>
                            </div>
                        
                    )}
                </div>
            )}
        </div>
    );
};
