import React, {useState} from "react";
import {
    WppStepper,
    WppStep,
    WppTopbar,
    WppButton,
    WppTypography,
} from "@wppopen/components-library-react";
import {useNavigate} from "react-router-dom";
import styles from "../Dashboard/data-ingestion-workflow.module.scss";
import {UploadFilesStep} from "../Common/UploadFilesStep";
import {TriggerDagStep} from "../Common/TriggerDagStep";
import {InstructionStep} from "../Common/InstructionStep";
import {NetDagConstants} from "./NetDagConstants";

const WORKFLOW_STEPS = 3;

export const NetCalcTestRunWorkflow = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [highestStep, setHighestStep] = useState(1);
    const [isDagSuccess, setIsDagSuccess] = useState(false);
    const [instructionStepChecked, setInstructionStepChecked] =
        useState(false);
    const [
        isInstructionStepPristine,
        setIsInstructionStepPristine,
    ] = useState(true);
    const [uploadFilesStepChecked, setUploadFilesStepChecked] = useState(false);
    const [isUploadFilesStepPristine, setUploadFilesStepPristine] =
        useState(true);

    const handleNextStep = () => {
        if (currentStep === WORKFLOW_STEPS) {
            return;
        }
        if (currentStep === 1) {
            setIsInstructionStepPristine(false);

            if (!instructionStepChecked) {
                return;
            }
        }
        if (currentStep === 2) {
            setUploadFilesStepPristine(false);

            if (!uploadFilesStepChecked) {
                return;
            }
        }

        const step = currentStep + 1;
        setCurrentStep(step);
        if (step > highestStep) {
            setHighestStep(step);
        }
    };
    const handlePreviousStep = () => {
        if (currentStep === 1) {
            return;
        }

        setCurrentStep(currentStep - 1);
    };

    const onDagSuccess = () => {
        setIsDagSuccess(true);
    };

    const getSelectedPage = () => {
        switch (currentStep) {
            case 1:
                return (
                    <InstructionStep
                        checked={instructionStepChecked}
                        onChange={setInstructionStepChecked}
                        title="Please read the instructions below to proceed with the Net Calculation Test Run:"
                        context={["This is used to test and check syntax in yml config files for net variables.",
                            "During test run, metadata for net variables will not be uploaded in snowflake DB.",
                            "Input config files will not be moved or stored in snowflake DB (User will be responsible for managing different versions)."]}
                        checkboxText="I have read and understood the instructions."
                        links={[]}
                    />
                );
            case 2:
                return (
                    <UploadFilesStep
                        checked={uploadFilesStepChecked}
                        onChange={setUploadFilesStepChecked}
                        
                        title="Upload Net YAML Files To GCS:"
                        links={[
                            {
                                label: "Confluence Wiki",
                                link: NetDagConstants.netTestRunWikiLink,
                                context: "Download yml template and find more information here",
                            },
                            {
                                label: "GCS Location",
                                link: NetDagConstants.netTestRunUploadUrl,
                                context: "Clean folder and upload prepared yml files here",
                            }
                        ]}
                        checkboxText="I have uploaded the config files."
                    />
                );
            case 3:
                return (
                    <TriggerDagStep
                        onDagSuccess={onDagSuccess}
                        configName={NetDagConstants.TriggerNetTestRunCalculationDAG}
                        survey=''
                    />
                );
        }
        return (
            <div>
                <h3>Page {currentStep}</h3>
            </div>
        );
    };

    const handleStepClick = (event) => {
        if (isDagSuccess) {
            return;
        }

        const step = event.detail.index;
        if (step <= highestStep) {
            setCurrentStep(step);
        }
    };

    const navigateToMIHome = () => {
        navigate("/data-processing/net-metadata-ingestion");
    };

    return (
        <div>
            <WppTopbar navigation={[]} style={{margin: "1rem"}}>
                <div slot="app">
                    <WppTypography type="l-midi" style={{width: "20rem"}}>
                        New Net Calculation Test Run
                    </WppTypography>
                </div>
            </WppTopbar>
            <div
                style={{
                    display: "flex",
                    margin: "2rem",
                }}
            >
                <WppStepper activeStep={currentStep} style={{width: "19rem"}}>
                    <WppStep
                        onWppStepChange={handleStepClick}
                        error={
                            !instructionStepChecked &&
                            !isInstructionStepPristine
                        }
                    >
                        <p slot="label">Instructions</p>
                    </WppStep>
                    <WppStep
                        onWppStepChange={handleStepClick}
                        error={!uploadFilesStepChecked && !isUploadFilesStepPristine}
                    >
                        {" "}
                        <p slot="label">Upload Files</p>
                    </WppStep>
                    <WppStep onWppStepChange={handleStepClick}>
                        <p slot="label">Trigger DAG</p>
                    </WppStep>
                </WppStepper>
                <div className={styles["stepper-container"]}>{getSelectedPage()}</div>
            </div>

            <div
                style={{
                    display: "flex",
                    margin: "2rem",
                    justifyContent: "flex-end",
                }}
            >
                {isDagSuccess ? (
                    <WppButton variant="secondary" onClick={navigateToMIHome}>
                        Go To Net Meta Home
                    </WppButton>
                ) : (
                    <>
                        <WppButton
                            variant="secondary"
                            onClick={handlePreviousStep}
                            disabled={isDagSuccess}
                            style={{marginRight: "1rem"}}
                        >
                            Previous Step
                        </WppButton>
                        <WppButton onClick={handleNextStep} disabled={isDagSuccess}>
                            Next Step
                        </WppButton>
                    </>
                )}
            </div>
        </div>
    );
};
