import React, {useEffect} from "react";
import {useState} from "react";
import ReactMarkdown from "react-markdown";
import {
    WppButton, WppIconAssistant,
    WppIconBot,
    WppInlineMessage, WppProgressIndicator, WppSkeleton,
    WppTextareaInput,
    WppTypography
} from "@wppopen/components-library-react";
import {WppTextareaInputCustomEvent} from "@wppopen/components-library/loader";
import {InputChangeEventDetail} from "@wppopen/components-library";

import {ChatBotAnswer} from "../../../types";
import {SemanticSearchEndPoints, SemanticSearchRequest} from "../../../api/audience-origin-semantics";
import { AppConfig } from "../../../AppConfig";

const Chatbot = ({ isQAModalOpen }) => {
    const [textareaText, setTextareaText] = useState("")
    const [error, setError] = useState("");
    const [chatHistory, setChatHistory] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!isQAModalOpen) {
            setChatHistory([]);
            setTextareaText('');
            setError('');
        }
    }, [isQAModalOpen]);

    const sampleOptions = [
        "What are the steps to ingest AO data?",
        "What are the input parameters of raw metadata pipeline?",
        "What does variable ordering pipeline do?",
        "What are different weight pipelines?",
        "what is the data size of AO data?",
        "what is the url of int snowflake?",
    ];

    const surprise = () => {
        const randomValue = Math.floor(Math.random() * sampleOptions.length);
        setTextareaText(sampleOptions[randomValue]);
    };

    const getResponse = async () => {
        if (!textareaText) {
            setError("Please enter a question");
            return;
        }
        const params ={
            embedding_model: "BAAI/bge-small-en-v1.5",
            llm_model: "gemini-1.5-flash-001",
            question: textareaText
        }
        setLoading(true);
        try {
            const response = await SemanticSearchRequest.post<ChatBotAnswer>(
                `${SemanticSearchEndPoints.AnswerQuestion}`,
                params
            );
            console.log(response.data);
            const data = response.data.answers;
            setChatHistory((oldChatHistory) => {
                const newChatHistory = [
                    ...oldChatHistory,
                    {
                        role: "User",
                        parts: textareaText,
                    },
                    {
                        role: "Bot",
                        parts: data,
                    },
                ];
                return newChatHistory.length > 6 ? newChatHistory.slice(-2) : newChatHistory;
            });
            setTextareaText("");
        } catch (error) {
            console.error(error);
            setError("Something went wrong. Please try again later.");
        }
        finally {
            setLoading(false);
        }
    };

    const clear = () => {
        setChatHistory([]);
        setTextareaText("");
        setError("");
    }

    return (
        <div>
            <WppTypography type="m-midi">
                I can answer any questions you have about the AO data processing. Try me!
            </WppTypography>
            <WppButton variant='secondary'
                style={{ display: 'block', marginTop: '1rem' }}
                onClick={surprise} disabled={!chatHistory}>
                    Give me a sample question
            </WppButton>
            <div>
                <WppTextareaInput
                    style={{marginTop: '1rem', marginBottom: '1rem'}}
                    slot="form-element"
                    size="s"
                    placeholder="Type your question here..."
                    value={textareaText}
                    onWppChange={(e: WppTextareaInputCustomEvent<InputChangeEventDetail>) => {
                        setTextareaText(e.detail.value)
                    }}
                />
                {error && <WppInlineMessage size="m" message={error} type="warning"/>}
                <div style={{marginTop: '0.5rem', marginBottom: '1rem'}}>
                    {!error && <WppButton onClick={getResponse}>Ask me</WppButton>}
                    {error && <WppButton onClick={clear}>Clear</WppButton>}
                </div>
                {loading && (
                    <WppSkeleton
                        variant="rectangle"
                        width="35vw"
                        height="20px"
                    />
                )}
                <div style={{ width: '40vw'}}>
                    {chatHistory.map((chatItem, _index) => (
                        <div key={_index} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '0.5rem' }}>
                            {chatItem.role === "Bot" ? <WppIconBot style={{ marginTop: '1rem', marginRight: '0.5rem' }} /> : <WppIconAssistant style={{ marginRight: '0.5rem' }} />}
                            <WppTypography type='m-midi' style={{ flex: 1, wordBreak: 'break-word'}}>
                                {chatItem.role === "Bot" ? (
                                    <ReactMarkdown >{chatItem.parts}</ReactMarkdown>
                                ) : (
                                    chatItem.parts
                                )}
                            </WppTypography>
                        </div>
                    ))}
                </div>
            </div>


        </div>
    )
}

export default Chatbot;