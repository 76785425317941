import React, { useEffect, useState } from "react";
import styles from "./questionnarie.module.css";
import { FormControl, Select, styled } from "@mui/material";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 120,
  maxWidth: 1000,
}));

interface SearchPanelProps {
  handleSearch: (text: string) => void;
  value :any;
  setSearchTerm:any;
}

export const SearchPanel = (props: SearchPanelProps) => {

  const handleInputChange = (event) => {
    const values = event.target.value;
    props.setSearchTerm(values);
    props.handleSearch(values);
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search by entering keywords or question topics... "
        value={props.value}
        onChange={handleInputChange}
      />
    </div>
  );
};
