import React, {useState} from "react";
import {
    WppNavSidebarItem,
    WppIconButton,
    WppIconNavigationMenu,
    WppIconBookmark, WppIconClose,
} from "@wppopen/components-library-react";

import {useNavigate} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {AudienceOriginRequest, EndPoints} from "../../../api/audience-origin";
import {ExportStatus, SurveySummary} from "../../../types";


function useSurveySummary() {
    return useQuery({
        queryKey: ["surveySummary"],
        queryFn: async () => {
            const {data} = await AudienceOriginRequest.post<SurveySummary[]>(
                EndPoints.SurveySummary
            );
            return data;
        },
    });
}

function useSurveyExportStatus() {
    return useQuery({
        queryKey: ["exportStatus"],
        queryFn: async () => {
            const {data} = await AudienceOriginRequest.post<ExportStatus[]>(
                EndPoints.ExportStatus
            );
            return data;
        },
    });
}

const Sidepanel = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [sidebarVisible, setSidebarVisible] = useState(true);

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };


    const {data, error, status} = useSurveySummary();

    const navigateToMetadataIngestion = () => {
        navigate("/data-processing/metadata-ingestion");
    };

    const navigateToNetMetadataIngestion = () => {
        navigate("/data-processing/net-metadata-ingestion");
    };

    const navigateToWeightMetadataIngestion = () => {
        navigate("/data-processing/weight-metadata-ingestion");
    }

    const navigateToRawDataIngestion = () => {
        navigate("/data-processing/raw-data-ingestion");
    };

    const navigateToAscriptionDataIngestion = () => {
        navigate("/data-processing/asc-data-ingestion");
    };

    const navigateToNetDataIngestion = () => {
        navigate("/data-processing/net-data-ingestion");
    };

    const navigateToWeightDataIngestion = () => {
        navigate("/data-processing/weight-data-ingestion");
    };

    const navigateToExport = () => {
        navigate("/data-processing/export-data");
    };

    const navigateToVariableOrder = () => {
        navigate("/data-processing/variable-order");
    };

    const navigateToDataFix = () => {
        navigate("/data-processing/data-fix");
    };


    return (
        <div>


            <WppIconButton style={{
                display: sidebarVisible ? 'none' : 'block',
                marginLeft: '1rem',
                marginTop: '2rem',
                width: '3rem',
                height: '3rem',
                fontSize: '1.5rem'
            }} onClick={toggleSidebar}>
                <WppIconNavigationMenu/>
            </WppIconButton>
            <div style={{display: "grid", gridTemplateColumns: "0fr 1fr", gap: "1rem"}}>

                <div id='sidebar' style={{
                    width: "13rem",
                    borderRight: sidebarVisible ? '1px groove' : 'none',
                    margin: "1rem 0",
                    marginLeft: "auto",
                    marginRight: "1rem",
                }}>

                    <div id="sidebar" style={{height: "48rem", display: sidebarVisible ? 'block' : 'none'}}>
                        <div className="list">
                            <p className='toggle-btn' style={{
                                marginInlineStart: 'auto',
                                marginTop: '1rem',
                                width: '3rem',
                                height: '3rem',
                                fontSize: '1.5rem'
                            }} onClick={toggleSidebar}><WppIconClose></WppIconClose></p>

                            <WppNavSidebarItem class='item' label="Meta" extended={true}>
                                <WppIconBookmark slot="icon-start"/>
                                <WppNavSidebarItem class='item' label="Raw Metadata " path="/meta1"
                                                   onClick={navigateToMetadataIngestion}></WppNavSidebarItem>
                                <WppNavSidebarItem class='item' label="Variable Ordering" path="/meta2"
                                                   onClick={navigateToVariableOrder}></WppNavSidebarItem>
                                <WppNavSidebarItem class='item' label="Net Metadata " path="/meta3"
                                                   onClick={navigateToNetMetadataIngestion}></WppNavSidebarItem>
                                <WppNavSidebarItem class='item' label="Weight Metadata " path="/meta4"
                                                   onClick={navigateToWeightMetadataIngestion}></WppNavSidebarItem>
                            </WppNavSidebarItem>
                            <WppNavSidebarItem class='item' label="Fact" extended={true}>
                                <WppIconBookmark slot="icon-start"/>
                                <WppNavSidebarItem class='item' label="Raw Data Ingestion" path="/fact1"
                                                   onClick={navigateToRawDataIngestion}></WppNavSidebarItem>
                                <WppNavSidebarItem class='item' label="Ascription Ingestion" path="/fact2"
                                                   onClick={navigateToAscriptionDataIngestion}></WppNavSidebarItem>
                            </WppNavSidebarItem>
                            <WppNavSidebarItem class='item' label="Calculation" extended={true}>
                                <WppIconBookmark slot="icon-start"/>
                                <WppNavSidebarItem class='item' label="Net Calculation" path="/calculation1"
                                                   onClick={navigateToNetDataIngestion}></WppNavSidebarItem>
                                <WppNavSidebarItem class='item' label="Weight Calculation" path="/calculation2"
                                                   onClick={navigateToWeightDataIngestion}></WppNavSidebarItem>
                            </WppNavSidebarItem>
                            <WppNavSidebarItem class='item' label="Export" extended={true}>
                                <WppIconBookmark slot="icon-start"/>
                                <WppNavSidebarItem class='item' label="Export Data" path="/export1"
                                                   onClick={navigateToExport}></WppNavSidebarItem>
                            </WppNavSidebarItem>
                            <WppNavSidebarItem class='item' label="Data Fixes" extended={true}>
                                <WppIconBookmark slot="icon-start"/>
                                <WppNavSidebarItem class='item' label="Data Fix" path="/datafix1"
                                                   onClick={navigateToDataFix}></WppNavSidebarItem>
                            </WppNavSidebarItem>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default Sidepanel;
