import React, { useState } from "react";
import styles from "./Question.module.css";
import { AppTopbar } from "../../components/Dashboard/AppTopbar";
import { WppBanner } from "@wppopen/components-library-react";

export const ErrorScreen = ({
  errorMessage,
  isToShowBanner,
  setIsToShowBanner,
}) => {
  const handleBannerShowStateChange = (event: CustomEvent) => {
    setIsToShowBanner(event.detail.show);
  };

  return (
    <>
      <WppBanner
        type="warning"
        show={isToShowBanner}
        closable
        onWppClose={handleBannerShowStateChange}
      >
        {errorMessage}
      </WppBanner>
    </>
  );
};
