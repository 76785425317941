import React, { useState, useEffect } from "react";
import { WppListItem } from "@wppopen/components-library-react";
import { WppAutocomplete } from '@wppopen/components-library-react';
import { AutocompleteDefaultOption } from "@wppopen/components-library";


type Props = {
    value: AutocompleteDefaultOption[];
    marketCodes: any[];
    onValueChange: (value: any[]) => void;
};

export const AddSurveyStep = (props: Props) => {
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const storedData = localStorage.getItem('surveyWaveData');
    let autoCompleteData = [], autoData;
    const [warningMessage, setWarningMessage] = useState<string>("");
    const [messageType, setMessageType] = useState<string>("");


    if (storedData && typeof storedData === 'string') {
        autoData = JSON.parse(storedData);
    }

    if (autoData) {
        autoCompleteData = props.marketCodes.map((value,index) => ({
            id: index,
            label: value.name
        }));
    }
    const handleSelectAll = () => {
        if (selectAll) {
            const allOptions = autoCompleteData.map(value => value.label);
            props.onValueChange(allOptions);
        } else {
            const allOptions = autoCompleteData.map(value => value.label);
            props.onValueChange(allOptions);
        }

        setSelectAll(!selectAll);
    };

    const handleNewmarketCreation = (event: CustomEvent) => {
        const newSurvey = event.detail.toUpperCase();
        const regex = /^([A-Z]{2})(,[A-Z]{2})*$/;
        const strictregex =  /^([A-Z]{2})(\s*,\s*[A-Z]{2})*$/;
        const isValid = regex.test(newSurvey)||strictregex.test(newSurvey);
        if (!isValid) {
            props.onValueChange([]);
            setMessageType("error");
            setWarningMessage("Invalid market name. Please enter a valid market name");
            return;
        } 
        const inputvalue = newSurvey.split(',').map(value => value.trim());
        const validlabel = autoCompleteData.map(Option=>Option.label.toUpperCase());

        const invalidvalues =inputvalue.filter(value => !validlabel.includes(value));
         if (invalidvalues.length > 0) {
            props.onValueChange([]);
            setMessageType("error");
            setWarningMessage(`Invalid market names: ${invalidvalues.join(', ')}`);
         }    
        else {
            setWarningMessage("")
            const newOption  =  inputvalue.map(value => ({
                id: Math.random(),
                label: value,
            }));
            props.onValueChange(newOption.map(options => options.label));

        }
    };

    const handleOptionClick = (option: AutocompleteDefaultOption) => {
        const isSelected = props.value.some(item => item.label === option.label);

        let updatedSelection;
        if (isSelected) {
            updatedSelection = props.value.filter(item => item.label !== option.label);
        } else {
            updatedSelection = [...props.value, option];
        }
        let value = updatedSelection.map(value =>value.label);
        props.onValueChange(value);
    };
   
    useEffect(() => {
        if (props.value.length === autoCompleteData.length) {
            setSelectAll(true);
        } else if (props.value.length === 0) {
            setSelectAll(false);
        } else {
            setSelectAll(false);
        }
    }, [props.value]);


   
    return (
        <div>
            <WppAutocomplete
                required={true}
                name="basic"
                placeholder="Select market"
               suggestionsTitle=''
               locales={
                {
                   "nothingFound": "Nothing found",
                   "more": "more",
                   "loading": "Loading...",
                   "selected": count => `${count} selected`,
                  
                   "createNewElement": "Create customized market list",
               }

                  }
                
               
               
                value={props.value}
                onWppChange={(e) => props.onValueChange(e.detail.value)}
                multiple={true}
                message={warningMessage}
             showCreateNewElement
             
                
                displayBtnWhenListEmpty={false}
                onWppCreateNewOption={handleNewmarketCreation}
                
            >
                <WppListItem value="select_all" key="select_all" onClick={handleSelectAll}>
                    <p slot="label">Select All</p>
                </WppListItem>

                <WppListItem value="clear_all" key="clear_all" onClick={() => props.onValueChange([])}>
                    <p slot="label">Clear All</p>
                </WppListItem>

                {autoCompleteData.map(option => {
                    const isSelected = props.value.some(value => value.label===option.label)
                    return (
                        <WppListItem
                            key={option.id}
                            value={option.label}
                            label={option.label}
                            onClick={() => handleOptionClick(option)}
                              style={{
                              cursor: 'pointer',
                              backgroundColor: isSelected ?'#EEE8FF':'' , 
                            
                            }}
                           
                        >
                            <p slot="label">{option.label}</p>
                        </WppListItem>
                    );
                })}
            </WppAutocomplete>
        </div>
    );
};

