import React, {useState } from "react";
import { AppTopbar } from "../../components/Dashboard/AppTopbar";
import styles from './QuestionnairePreviewer.module.css';
import {WppButton, WppToggle, WppModal,  WppProgressIndicator} from '@wppopen/components-library-react'
import QuestionnaireSchemaPreviewer from "./QuestionnaireSchemaPreviewer";
import QuestionnaireFormPreviewer from "./QuestionnaireFormPreviewer";
import { useNavigate } from "react-router-dom";
import {
  selectPreviewerValues,
  selectBuilderValues,
  selectQuestionsMetadata,
  selectId,
  selectVersion, setQuestionsMetadata, setPreviewerValues, setBuilderValues
} from "../../stores/questionnaireSlice";
import { convertQuestionJsonToArray, removeKeysFromObject } from "../Utils";
import { useSelector, useDispatch} from "react-redux";
import {Questionnaire, QuestionsMetaDnd} from "../../types";
import {AOQuestionnaireEndPoints, AOQuestionnaireRequest} from "../../api/audience-origin-questionnaire";
import {getDownloadFile} from "../Utils/FileDownloader";
import axios from "axios";
import {addAnsVariable, findDuplicatesInQuestions} from "../Utils/SchemaHelpers";

const QuestionnairePreviewer: React.FC = () => {
    const previewerFormValues = useSelector(selectPreviewerValues);
    const builderFormValues = useSelector(selectBuilderValues);
    const builderMeta = useSelector(selectQuestionsMetadata);
    const questionnaireId = useSelector(selectId);
    const questionnaireVersion = useSelector(selectVersion);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toggleLabelConfig={
        text: 'Schema Preview'
    }

    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [isExported, setIsExported] = useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>("");
    const [isExportModalOpened, setExportIsModalOpened] = useState<boolean>(false);
    const [exportModalMessage, setExportModalMessage] = useState<string>("");

  const navigateToSurveyBuilder = async () => {
    navigate("/questionnaire-design/builder");
  };

  const navigateToCompare = async () => {
    const questionsData = getQuestionnaireJson();
    const compareObject = {
      questionnaire_1_id: questionnaireId,
      questionnaire_1_version: questionnaireVersion,
      questionnaire_2_json: {
        questions: questionsData.questions,
      },
    };
    try {
      const response = await AOQuestionnaireRequest.post(
        `${AOQuestionnaireEndPoints.CompareQuestionnaire}`,
        compareObject
      );
      navigate("/questionnaire-design/compare", {
        state: {
          differences: response.data,
          question1: "",
          question2: "",
        },
      });
    } catch (error) {
      console.log(error);
    }
  };




  const getQuestionnaireJson = () => {
    const keysToRemove = ["survey_name", "description", "methodology", "type"];
    const questions_raw = removeKeysFromObject(
      previewerFormValues,
      keysToRemove
    );
    const questions_data = convertQuestionJsonToArray(questions_raw);
    const question_data_with_ans_variable = addAnsVariable(questions_data);
    const questionnaire = {
      name: previewerFormValues.survey_name,
      description: previewerFormValues.description,
      methodology: previewerFormValues.methodology,
      type: previewerFormValues.type,
      questions: {
        questions_data: question_data_with_ans_variable ,
        questions_builder: {
          meta: builderMeta,
          form_values: builderFormValues,
        },
        questions_previewer: previewerFormValues,
      },
      state: "IN_DEVELOP",
    };
    return questionnaire;
  };

    const onSubmitQuestionnaire = async () => {
        console.log("onSubmitQuestionnaire, id is..")
        console.log(questionnaireId);
        setIsLoading(true);
        try {
           const currentQuestionnaire = getQuestionnaireJson();
           const duplicates = findDuplicatesInQuestions(currentQuestionnaire.questions.questions_data);
           if (duplicates.length > 0){
               setIsLoading(false);
               setModalMessage("Please remove duplicates before saving:" + duplicates.join("\n"));
               setIsModalOpened(true);
               return;}

            if (questionnaireId === null) {
            await AOQuestionnaireRequest.post<Questionnaire>(
                `${AOQuestionnaireEndPoints.QuestionnaireList}`, currentQuestionnaire
            );
           }
            else{
                await AOQuestionnaireRequest.put<Questionnaire>(
                `${AOQuestionnaireEndPoints.QuestionnaireList}/${questionnaireId}`, currentQuestionnaire
            );

            }
            setIsLoading(false);
            setModalMessage("Questionnaire created successfully !");
            // response.data is the array of data
        } catch (error) {
            setIsLoading(false);
            if (axios.isAxiosError(error)) {
                // Handle the axios error here
                console.log(error.response?.data);
                console.log(error.response?.status);
                setError(error.message);
                if(error.response?.status === 400){
                    setModalMessage(error.response?.data[0].message)
                }

            } else {
                // Handle unexpected errors
                setError('An unexpected error occurred');
                setModalMessage(error.message)
            }
        } finally {
            setIsModalOpened(true);
        }
  };

    const handleCloseModal = () => {
        if(error !== null){
            setIsModalOpened(false);}
        else{
            setIsModalOpened(false);
            dispatch(setQuestionsMetadata(null));
            dispatch(setPreviewerValues(null));
            dispatch(setBuilderValues(null));
            navigate("/questionnaire-design");

        }
    };

    const onExportQuestionnaire = () => {
        setExportIsModalOpened(true);
        setExportModalMessage("Current version will be saved as the newest version before exporting, please confirm if you would like to continue...")
    };

    const handleExportCloseModal = () => {
        if(isExported){
            setExportIsModalOpened(false);
            dispatch(setQuestionsMetadata([] as QuestionsMetaDnd[]));
            dispatch(setPreviewerValues(null));
            dispatch(setBuilderValues(null));
            navigate("/questionnaire-design");
        }
        else{
            setExportIsModalOpened(false);
        }
    };

    const handleExportModal = async() => {
        console.log("Exporting questionnaire ...");
        setIsLoading(true);
        const currentQuestionnaire = getQuestionnaireJson();
        const duplicates = findDuplicatesInQuestions(currentQuestionnaire.questions.questions_data);
        if (duplicates.length > 0){
            setIsLoading(false);
            setExportModalMessage("Please remove duplicates before saving:" + duplicates.join("\n"));
            return;}
        try {
            let response;
            let fileId=questionnaireId;
            if (questionnaireId === null) {
              response= await AOQuestionnaireRequest.post<Questionnaire>(
                    `${AOQuestionnaireEndPoints.QuestionnaireList}`, getQuestionnaireJson()
                );
                fileId = response.data.id;
            }
            else {
                response= await AOQuestionnaireRequest.put<Questionnaire>(
                    `${AOQuestionnaireEndPoints.QuestionnaireList}/${questionnaireId}`, getQuestionnaireJson()
                );
            };

      if (response.status === 200 || response.status === 201) {
        console.log("saved current version, now exporting ...");

            const fileResponse = await getDownloadFile(fileId, previewerFormValues.survey_name.replace(/ /g,"_"));
            if(fileResponse.status==200) {
                setIsExported(true);
                setIsLoading(false);
                setExportModalMessage("Exported successfully!")
              }
          if (axios.isAxiosError(fileResponse)) {
              setIsLoading(false);
              console.log(fileResponse.response?.data);
              console.log(fileResponse.response?.status);
              setError("Download failed." + fileResponse.response?.data[0].message)

          }
            }
        } catch (error) {
            setIsLoading(false);
                // Handle unexpected errors
                setError('An unexpected error occurred');
                setExportModalMessage(error.message)
            }
        };

  return (
    <div>
      <AppTopbar> </AppTopbar>
      <div className={styles["questionnaire-previewer-header"]}>
        <WppButton
          className="wpp-spacing-12-right"
          onClick={navigateToSurveyBuilder}
          variant='secondary'
        >
          Edit Again
        </WppButton>
        <WppButton
          disabled={questionnaireId ? false : true}
          className="wpp-spacing-12-right"
          onClick={navigateToCompare}
          variant='secondary'
        >
          Compare
        </WppButton>
          <WppButton
              className="wpp-spacing-12-right"
              onClick={onExportQuestionnaire}
              variant='secondary'
          >
              Export
          </WppButton>
        <WppButton
          className="wpp-spacing-48-right"
          onClick={onSubmitQuestionnaire}
          variant='primary'
        >
          Save
        </WppButton>

      </div>
      <div className={styles["questionnaire-previewer-container"]}>
        <WppToggle
          disabled={false}
          required={true}
          labelConfig={toggleLabelConfig}
          checked={isChecked}
          onWppChange={({ detail: { checked } }) => setIsChecked(checked)}
        />
        {isChecked ? (
          <QuestionnaireSchemaPreviewer></QuestionnaireSchemaPreviewer>
        ) : (
          <QuestionnaireFormPreviewer></QuestionnaireFormPreviewer>
        )}
      </div>
        <WppModal open={isLoading}>
            <WppProgressIndicator variant="circle" />
        </WppModal>
      <WppModal open={isModalOpened}>
        <h3 slot="header">Save Questionnaire ....</h3>
        <p slot="body">{modalMessage}</p>
        <div slot="actions">
          <WppButton variant="primary" size="s" onClick={handleCloseModal}>
            Close
          </WppButton>
        </div>
      </WppModal>
      <WppModal open={isExportModalOpened}>
        <h3 slot="header">Export Questionnaire</h3>
        <p slot="body">{exportModalMessage}</p>
        <div slot="actions">
          <WppButton
            disabled={isExported}
            variant="secondary"
            size="s"
            className="wpp-spacing-16-right"
            onClick={handleExportModal}
          >
            Continue
          </WppButton>
          <WppButton
            variant="secondary"
            size="s"
            onClick={handleExportCloseModal}
          >
            Close
          </WppButton>
        </div>
      </WppModal>
    </div>
  );
};

export default QuestionnairePreviewer;
