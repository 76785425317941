import React, {useState} from "react";
import {
    WppStepper,
    WppStep,
    WppTopbar,
    WppButton,
    WppTypography,
} from "@wppopen/components-library-react";
import {useNavigate} from "react-router-dom";
import styles from "../Dashboard/data-ingestion-workflow.module.scss";
import {InstructionStep} from "../Common/InstructionStep";
import {UploadFilesStep} from "../Common/UploadFilesStep";
import {AppConfig,getIngestionGCSPrefix} from "../../../AppConfig";
import {WeightDagConstants} from "./WeightDagConstants";
import {TriggerDagStep} from "../Common/TriggerDagStep";
import {NetDagConstants} from "./../NetVariableIngestion/NetDagConstants";
import { SurveySelectStep } from "../Common/SurveySelectStep";
import {MetadataDiffStep} from "../Common/MetadataDiffStep";
import { useQuery } from "@tanstack/react-query";
import { AudienceOriginRequest, EndPoints } from "../../../api/audience-origin";
import { MetadataDiff } from "../../../types/audience-origin/metadata/metadata-diff";
import { info } from "console";
import { CombinedWeightDiffStep } from "../Common/CombinedWeightDiffStep";
import { WeightDiffexcelStep } from "../Common/weightDiffexcelStep";
import {AdditionalYamldiffStep} from "../Common/AdditionalYamldiffStep";

const WORKFLOW_STEPS = 6;


const getStepInitialData = (noOfSteps: number) => {
    return Array.from({length: noOfSteps}, (_, index) => ({
        [index]: {checked: false, error: false},
    })).reduce((p, c) => {
        return {...p, ...c};
    }, {});
};

export const WeightConfigIngestionWorkflow = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [highestStep, setHighestStep] = useState(1);
    const [isDagSuccess, setIsDagSuccess] = useState(false);
    const [instructionStepChecked, setInstructionStepChecked] = useState(false);
    const [isInstructionStepPristine, setIsInstructionStepPristine] = useState(true);
    const [fileDiff, setFileDiff] = useState("");
    const [yamlFileDiff, setYamlFileDiff] = useState("");

    const [surveyData, setsurveyData] = useState({survey: String, YEAR: 0});
    const [uploadFilesStepChecked, setUploadFilesStepChecked] = useState(false);
    const [isUploadFilesStepPristine, setUploadFilesStepPristine] = useState(true);
    const [inputFileName, setInputFileName] = useState([]);
    const [pageStatus, setPageStatus] = useState(
        getStepInitialData(WORKFLOW_STEPS)
    );
    const [survey, setSurvey] = useState("");
    const [areDicFilesUploadedSuccessfully, setAreDicFilesUploadedSuccessfully] = useState(false);
    const [areSpecFilesUploadedSuccessfully, setAreSpecFilesUploadedSuccessfully] = useState(false);

    const handleSurveyChange = (selectedSurvey: string) => {
        try {
            setCurrentStep(2);
            setHighestStep(2);
            setPageStatus(getStepInitialData(WORKFLOW_STEPS));
            setAreDicFilesUploadedSuccessfully(false);
            setAreSpecFilesUploadedSuccessfully(false);
            setSurvey(selectedSurvey);
            
            const storedData = localStorage.getItem('surveyWaveData');
            if (storedData) {
                var tempData = JSON.parse(storedData);
            }
            tempData = tempData.filter(item => item.SURVEY_NAME === selectedSurvey)[0];
            setsurveyData(tempData);
            
            let data = JSON.parse(localStorage.getItem('weightMetadataFilesInfo'));
            let inputFileNames = data
                .filter(survey => survey.SURVEY === selectedSurvey)
                .map(survey => survey.CONFIG_FILE_NAME);
            
            setInputFileName(inputFileNames);
            setPageStatus({
                ...pageStatus,
                [currentStep]: {checked: true, error: false},
            });
        } catch (error) {
            console.error("Error in survey change:", error);
        }
    };

    const handleNextStep = async () => {
        try {
            if (currentStep === WORKFLOW_STEPS) {
                return;
            }

            if (currentStep === 1) {
                setIsInstructionStepPristine(false);
                if (!instructionStepChecked) {
                    return;
                }
            }

            if (currentStep === 2) {
                if (survey.trim().length === 0) {
                    return;
                }
            }

            if (currentStep === 3) {
                setUploadFilesStepPristine(false);
                if (!uploadFilesStepChecked) {
                    return;
                }

                // const basePath = 
                // process.env.REACT_APP_ENV === 'qa'
                // ?"gs://gcs-gcp-cgh-dc-ao-npd-amer-ao-nonprod/pipelines/ao_weights_metadata/configs/"
                // :process.env.REACT_APP_ENV === 'int'
                // ?"gs://gcs-gcp-cgh-dc-ao-npd-amer-ao-nonprod/int/pipelines/ao_weights_metadata/configs/"
                // :"gs://gcs-gcp-cgh-dc-ao-npd-amer-ao-nonprod/pipelines/ao_weights_metadata/configs/";

                //let tempYamlFileName = "gs://gcs-gcp-cgh-dc-ao-npd-amer-ao-nonprod/pipelines/ao_weights_metadata/configs";
               // let tempFileName = "gs://gcs-gcp-cgh-dc-ao-npd-amer-ao-nonprod/pipelines/ao_weights_metadata/configs";
                let tempYamlFileName = "";
                let tempFileName = "";
              const  basepath = getIngestionGCSPrefix(AppConfig.environment, "weight_config").replace("https://console.cloud.google.com/storage/browser/", "gs://");

                // Constructing file paths dynamically based on the uploaded files
                if (inputFileName && inputFileName.length > 0) {
                    for (const file of inputFileName) {
                        if (file.includes(".yaml")) {
                            tempYamlFileName = file;
                        } else if (file.includes(".xlsx")) {
                            tempFileName = file;
                        }
                    }
                }
                if (inputFileName.length > 0) {
                    for (const file of inputFileName) {
                        if (file.includes(".yaml")) {
                            tempYamlFileName = basepath + file;
                        } else if (file.includes(".xlsx")) {
                            tempFileName = basepath + file;
                        }
                    }
                }
                setFileDiff(tempFileName);
                setYamlFileDiff(tempYamlFileName);
            }

            if (currentStep === 4 || currentStep === 5) {
                try {
                    console.log('Processing step', currentStep);
                } catch (error) {
                    console.error(`Error in step ${currentStep}:`, error);
                }
            }

            const nextStep = currentStep + 1;
            setCurrentStep(nextStep);
            if (nextStep > highestStep) {
                setHighestStep(nextStep);
            }
        } catch (error) {
            console.error("Error in handleNextStep:", error);
            const nextStep = currentStep + 1;
            setCurrentStep(nextStep);
            if (nextStep > highestStep) {
                setHighestStep(nextStep);
            }
        }
    };

    const handlePreviousStep = () => {
        if (currentStep === 1) {
            return;
        }
        setCurrentStep(currentStep - 1);
    };

    const onDagSuccess = () => {
        setIsDagSuccess(true);
    };

    const handleStepClick = (event) => {
        if (isDagSuccess) {
            return;
        }

        const step = event.detail.index;
        if (step <= highestStep) {
            setCurrentStep(step);
        }
    };

    const navigateToMIHome = () => {
        navigate("/data-processing/weight-metadata-ingestion");
    };

    const getSelectedPage = () => {
        switch (currentStep) {
            case 1:
                return (
                    <InstructionStep
                        checked={instructionStepChecked}
                        onChange={setInstructionStepChecked}
                        title="Please read the instructions below to proceed with the Weight Config Files Ingestion Run:"
                        context={[
                            "This is used to upload calculated weights and projections config files to snowflake DB.",
                            "The latest uploaded set is considered as newer version amd will take precedence over previous ones.",
                        ]}
                        checkboxText="I have read and understood the instructions."
                        links={[]}
                    />
                );
            case 2:
                return (
                    <SurveySelectStep 
                        value={survey} 
                        onValueChange={handleSurveyChange}
                    />
                );
            case 3:
                return (
                    <UploadFilesStep
                        checked={uploadFilesStepChecked}
                        onChange={setUploadFilesStepChecked}
                        title="Upload Weight Config Files To Snowflake DB:"
                        links={[
                            {
                                label: "Confluence Wiki",
                                link: WeightDagConstants.weightConfigIngestionWikiLink,
                                context: "Find detailed instruction for weight config file ingestion here",
                            },
                            {
                                label: "GCS Location",
                                link: WeightDagConstants.weightConfigDataUploadUrl.replace("/configs/","/configs"),
                                context: "Use File Upload Widget below or clean folder and upload prepared file directly here",
                            }
                        ]}
                        checkboxText="I have uploaded the config files."
                    />
                );
            case 4:
                // const gcsFileLocation = 
                //     process.env.REACT_APP_ENV === 'int'
                //         ? "gs://gcs-gcp-cgh-dc-ao-npd-amer-ao-nonprod/int/pipelines/ao_weights_metadata/configs"
                //         : "gs://gcs-gcp-cgh-dc-ao-npd-amer-ao-nonprod/pipelines/ao_weights_metadata/configs";
                
                return (
                    <div>
                        <CombinedWeightDiffStep
                           
                            gcsFileLocation2={fileDiff}
                            wave={survey}
                            onSuccess={setAreDicFilesUploadedSuccessfully}
                        />
                    </div>
                );
            case 5:
                return (
                    <AdditionalYamldiffStep 
                        gcsFileLocation2={yamlFileDiff}
                        survey={survey}
                        onSuccess={setAreDicFilesUploadedSuccessfully}
                    />
                );
            case 6:
                return (
                    <TriggerDagStep
                        onDagSuccess={onDagSuccess}
                        configName={WeightDagConstants.TriggerWeightMetadataIngestionDAG}
                        survey={survey}
                    />
                );
            default:
                return (
                    <div>
                        <h3>Page {currentStep}</h3>
                    </div>
                );
        }
    };

    return (
        <div>
            <WppTopbar navigation={[]} style={{margin: "1rem"}}>
                <div slot="app">
                    <WppTypography type="l-midi" style={{width: "20rem"}}>
                        New Weight Config files Ingestion
                    </WppTypography>
                </div>
            </WppTopbar>

            <div style={{ display: "flex", margin: "2rem" }}>
                <WppStepper activeStep={currentStep} style={{width: "19rem", display: 'block'}}>
                    <WppStep
                        onWppStepChange={handleStepClick}
                        error={!instructionStepChecked && !isInstructionStepPristine}
                    >
                        <p slot="label">Instructions</p>
                    </WppStep>
                    <WppStep
                        error={pageStatus[2].error}
                        onWppStepChange={handleStepClick}
                    >
                        <p slot="label" className={styles.text}>
                            Survey
                        </p>
                    </WppStep>
                    <WppStep
                        onWppStepChange={handleStepClick}
                        error={!uploadFilesStepChecked && !isUploadFilesStepPristine}
                    >
                        <p slot="label">Upload Files</p>
                    </WppStep>
                    <WppStep onWppStepChange={handleStepClick}>
                        <p className={styles.text} slot="label">
                            Compare Excel Dictionary Files
                        </p>
                    </WppStep>
                    <WppStep style={{display:'block'}} onWppStepChange={handleStepClick}>
                        <p className={styles.text} slot="label">
                            Compare Yaml Dictionary Files
                        </p>
                    </WppStep>
                    <WppStep onWppStepChange={handleStepClick}>
                        <p slot="label">Trigger DAG</p>
                    </WppStep>
                </WppStepper>

                <div className={styles["stepper-container"]}>
                    {getSelectedPage()}
                </div>
            </div>

            <div style={{
                display: "flex",
                margin: "2rem",
                justifyContent: "flex-end",
            }}>
                {isDagSuccess ? (
                    <WppButton variant="secondary" onClick={navigateToMIHome}>
                        Go To Weight Meta Data Ingestion Home
                    </WppButton>
                ) : (
                    <>
                        <WppButton
                            variant="secondary"
                            onClick={handlePreviousStep}
                            disabled={isDagSuccess}
                            style={{marginRight: "1rem"}}
                        >
                            Previous Step
                        </WppButton>
                        <WppButton 
                            onClick={handleNextStep} 
                            disabled={isDagSuccess}
                        >
                            Next Step
                        </WppButton>
                    </>
                )}
            </div>
        </div>
    );
};