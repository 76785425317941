import React, { useState } from "react";
import { Questionnaire, QuestionnaireMetaData } from "../../types";
import styles from "./questionnarie.module.css";
import {
  WppIconDownload,
  WppCard,
  WppCheckbox,
  WppActionButton,
  WppModal,
  WppBanner,
} from "@wppopen/components-library-react";
import { Flex } from "antd";
import { selectedQuestionProps } from "./QuestionnaireHomePage";
import {
  AOQuestionnaireEndPoints,
  AOQuestionnaireRequest,
} from "../../api/audience-origin-questionnaire";
import { getDownloadFile } from "../Utils/FileDownloader";
import axios from "axios";
import {
  setQuestionsMetadata,
  setBuilderValues,
  setPreviewerValues,
  setId,
  setQuestionVersion,
} from "../../stores/questionnaireSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ErrorScreen } from "../QuestionComponents/ErrorScreen";

type CardsPanelProps = {
  questionList: QuestionnaireMetaData[];
  showCheckBoxesView: boolean;
  selectedQuestions: selectedQuestionProps[];
  handleCheckboxChange: (event, question) => void;
};

export const CardsPanel = (props: CardsPanelProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isToShowBanner, setIsToShowBanner] = useState(false);

  const handleBannerShowStateChange = (event: CustomEvent) => {
    setIsToShowBanner(false);
  };
  const TextWithEllipsis = ({
    text,
    longStyle,
    shortStyle,
    lengthThreshold,
  }) => {
    const textClass = text.length > lengthThreshold ? longStyle : shortStyle;
    return (
      <div className={styles[textClass]} title={text}>
        {text}
      </div>
    );
  };

  const validateChecked = (id: number): boolean => {
    let checked = false;
    props.selectedQuestions.forEach((item) => {
      if (item.id === id) {
        checked = true;
      }
    });
    return checked;
  };

  const disabledCheckbox = (question) => {
    if (props.selectedQuestions.length > 1) {
      if (!validateChecked(question.id)) {
        return true;
      }
    }
    return false;
  };

  const getQuestionnaireDetails = async (id) => {
    try {
      const response = await AOQuestionnaireRequest.get<Questionnaire>(
        `${AOQuestionnaireEndPoints.QuestionnaireList}/${id}`
      );
      dispatch(
        setBuilderValues(response.data.questions.questions_builder.form_values)
      );
      dispatch(
        setQuestionsMetadata(response.data.questions.questions_builder.meta)
      );
      
      navigate("/questionnaire-design/builder");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response.data);
        setError(error.response.data);
      } else {
        console.log(error);
        setError(
          "Corrupted data for the selected questionnaire id-" +
            id +
            ", please select another one. Error message: " +
            error.message
        );
      }
      setIsToShowBanner(true);
    }
  };

  const handleClick = async (card) => {
    getQuestionnaireDetails(card.id);
  };

  const onDownloadClick = async (card) => {
    try {
      const result = await getDownloadFile(
        card.id,
        card.name.replace(/ /g, "_")
      );
      console.log(result);
      if (axios.isAxiosError(result)) {
        console.log(result.response?.data);
        console.log(result.response?.status);
        setError(
          "Download for selected questionnaire id-" +
            card.id +
            " failed." +
            result.response?.data[0].message
        );
        setIsToShowBanner(true);
      }
    } catch (error) {
      // Handle unexpected errors
      setError(
        "Download for selected questionnaire id-" +
          card.id +
          " failed." +
          error.message
      );
      setIsToShowBanner(true);
    }
  };

  return (
    <div className={styles["cards-panel"]}>
      <WppBanner
        type="warning"
        show={isToShowBanner}
        closable
        onWppClose={handleBannerShowStateChange}
        className={styles["warning-banner"]}
      >
        {error}
      </WppBanner>
      <div className={styles["cards-list"]}>
        {props.questionList.map((card, index) => (
          <div key={index} onDoubleClick={() => handleClick(card)}>
            <WppCard
              size="2xl"
              interactive={true}
              className={styles["question-card"]}
            >
              <div>
                <div className={styles["card"]}>
                  <Flex justify="space-between">
                    <TextWithEllipsis
                      text={card.name}
                      longStyle={"card-title-long"}
                      shortStyle={"card-title"}
                      lengthThreshold={30}
                    ></TextWithEllipsis>
                    {props.showCheckBoxesView ? (
                      <WppCheckbox
                        style={{ marginLeft: "10px" }}
                        checked={validateChecked(card.id)}
                        indeterminate={false}
                        labelConfig={{
                          text: "",
                        }}
                        onWppChange={(e) => props.handleCheckboxChange(e, card)}
                        disabled={disabledCheckbox(card)}
                      />
                    ) : null}
                  </Flex>

                  <TextWithEllipsis
                    text={card.description}
                    longStyle={"long-text-style"}
                    shortStyle={"normal-text-style"}
                    lengthThreshold={45}
                  ></TextWithEllipsis>
                  <p className={styles["card-time"]}>{card.created_at}</p>
                  <p className={styles["card-status"]}>{card.state}</p>
                </div>
                <div slot="actions" className={styles["card-download"]}>
                  <WppActionButton
                    onClick={() => onDownloadClick(card)}
                    variant="secondary"
                  >
                    <WppIconDownload slot="icon-start" />
                  </WppActionButton>
                </div>
              </div>
            </WppCard>
          </div>
        ))}
      </div>
    </div>
  );
};
