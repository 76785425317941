import React, {useState} from "react";
import {
    WppStepper,
    WppStep,
    WppTopbar,
    WppButton,
    WppTypography,
} from "@wppopen/components-library-react";
import {useNavigate} from "react-router-dom";
import styles from "../Dashboard/data-ingestion-workflow.module.scss";
import {InstructionStep} from "../Common/InstructionStep";
import {WeightDagConstants} from "./WeightDagConstants";
import {UploadFilesStep} from "../Common/UploadFilesStep";
import {TriggerDagStep} from "../Common/TriggerDagStep";

const WORKFLOW_STEPS = 3;

export const WeightCalcGlobalFilesWorkflow = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [highestStep, setHighestStep] = useState(1);
    const [isDagSuccess, setIsDagSuccess] = useState(false);
    const [instructionStepChecked, setInstructionStepChecked] =
        useState(false);
    const [
        isInstructionStepPristine,
        setIsInstructionStepPristine,
    ] = useState(true);
    const [uploadFilesStepChecked, setUploadFilesStepChecked] = useState(false);
    const [isUploadFilesStepPristine, setUploadFilesStepPristine] =
        useState(true);
    const handleNextStep = () => {
        if (currentStep === WORKFLOW_STEPS) {
            return;
        }
        if (currentStep === 1) {
            setIsInstructionStepPristine(false);

            if (!instructionStepChecked) {
                return;
            }
        }
        if (currentStep === 2) {
            setUploadFilesStepPristine(false);

            if (!uploadFilesStepChecked) {
                return;
            }
        }

        const step = currentStep + 1;
        setCurrentStep(step);
        if (step > highestStep) {
            setHighestStep(step);
        }
    };
    const handlePreviousStep = () => {
        if (currentStep === 1) {
            return;
        }

        setCurrentStep(currentStep - 1);
    };

    const onDagSuccess = () => {
        setIsDagSuccess(true);
    };

    const getSelectedPage = () => {
        switch (currentStep) {
            case 1:
                return (
                    <InstructionStep
                        checked={instructionStepChecked}
                        onChange={setInstructionStepChecked}
                        title="Please read the instructions below to proceed with the weight global file calculation:"
                        context={["This is used to calculated weight variables global file.",
                            "This pipeline only calculates values for weight variables in one global file and does not upload to snowflake DB.",
                            "please sort the rows in the input file based on markets for optimized processing time."
                        ]}
                        checkboxText="I have read and understood the instructions."
                        links={[]}
                    />
                );
            case 2:
                return (
                    <UploadFilesStep
                        checked={uploadFilesStepChecked}
                        onChange={setUploadFilesStepChecked}
                        title="Upload SPSS Files for weight global file calculation:"
                        links={[
                            {
                                label: "Confluence Wiki",
                                link: WeightDagConstants.weightGlobalFileCalculationWikiLink,
                                context: "Find detailed instruction for weight global file calculation here",
                            },
                            {
                                label: "GCS Location",
                                link: WeightDagConstants.weightCalcGlobalFileUploadPath,
                                context: "Clean folder and upload prepared spss file here",
                            }
                        ]}
                        checkboxText="I have uploaded the config files."
                    />
                );
            case 3:
                return (
                    <TriggerDagStep
                        onDagSuccess={onDagSuccess}
                        configName={WeightDagConstants.TriggerWeightCalGlobalFileDAG}
                        survey=''
                    />
                );
        }
        return (
            <div>
                <h3>Page {currentStep}</h3>
            </div>
        );
    };

    const handleStepClick = (event) => {
        if (isDagSuccess) {
            return;
        }

        const step = event.detail.index;
        if (step <= highestStep) {
            setCurrentStep(step);
        }
    };

    const navigateToMIHome = () => {
        navigate("/data-processing/weight-data-ingestion");
    };

    return (
        <div>
            <WppTopbar navigation={[]} style={{margin: "1rem"}}>
                <div slot="app">
                    <WppTypography type="l-midi" style={{width: "20rem"}}>
                        New Weight Calculation : Global File
                    </WppTypography>
                </div>
            </WppTopbar>
            <div
                style={{
                    display: "flex",
                    margin: "2rem",
                }}
            >
                <WppStepper activeStep={currentStep} style={{width: "19rem"}}>
                    <WppStep
                        onWppStepChange={handleStepClick}
                        error={
                            !instructionStepChecked &&
                            !isInstructionStepPristine
                        }
                    >
                        <p slot="label">Instructions</p>
                    </WppStep>
                    <WppStep
                        onWppStepChange={handleStepClick}
                        error={!uploadFilesStepChecked && !isUploadFilesStepPristine}
                    >
                        {" "}
                        <p slot="label">Upload Files</p>
                    </WppStep>
                    <WppStep onWppStepChange={handleStepClick}>
                        <p slot="label">Trigger DAG</p>
                    </WppStep>
                </WppStepper>
                <div className={styles["stepper-container"]}>{getSelectedPage()}</div>
            </div>

            <div
                style={{
                    display: "flex",
                    margin: "2rem",
                    justifyContent: "flex-end",
                }}
            >
                {isDagSuccess ? (
                    <WppButton variant="secondary" onClick={navigateToMIHome}>
                        Go To Weight Data Ingestion Home
                    </WppButton>
                ) : (
                    <>
                        <WppButton
                            variant="secondary"
                            onClick={handlePreviousStep}
                            disabled={isDagSuccess}
                            style={{marginRight: "1rem"}}
                        >
                            Previous Step
                        </WppButton>
                        <WppButton onClick={handleNextStep} disabled={isDagSuccess}>
                            Next Step
                        </WppButton>
                    </>
                )}
            </div>
        </div>
    );
};
