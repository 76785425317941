import {
  WppSelect,
  WppListItem,
} from "@wppopen/components-library-react";
import React from "react";

interface QuestionareSelectProps {
  handleChange: (event: CustomEvent) => void;
}

export const QuestionareSelect = (props: QuestionareSelectProps) => {
  return (
    <WppSelect
      onWppChange={props.handleChange}
      placeholder="Semantic Search"
      value="apartment"
    >
      <WppListItem value="SearchTerm">
        <p slot="label">Semantic Search</p>
      </WppListItem>
      <WppListItem value="Label">
        <p slot="label">Variable Name</p>
      </WppListItem>
    </WppSelect>
  );
};
