import React, {useState } from "react";
import { Questionnaire} from "../../types";
import styles from "./questionnarie.module.css";
import {
  WppCard,
  WppCheckbox,
  WppBanner, WppTypography,
} from "@wppopen/components-library-react";
import {
  AOQuestionnaireEndPoints,
  AOQuestionnaireRequest,
} from "../../api/audience-origin-questionnaire";
import { getDownloadFile } from "../Utils/FileDownloader";
import axios from "axios";
import {
  setQuestionsMetadata,
  setBuilderValues,
  setPreviewerValues,
  setId,
  setQuestionVersion,
} from "../../stores/questionnaireSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

type CardsPanelProps = {
  type: String;
  code: String;
  selectedQuestion: String;
  questionCode: String[];
  answerCode: any[];
  showCheckBoxesView: boolean;
  handleCheckboxChange: (event, question: any[]) => void;
  IsChecked: (question: any[]) => boolean;
  question: any;
};

export const SearchCardsPanel = (props: CardsPanelProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isToShowBanner, setIsToShowBanner] = useState(false);

  const handleBannerShowStateChange = (event: CustomEvent) => {
    setIsToShowBanner(false);
  };

  const TextWithEllipsis = ({
    text,
    longStyle,
    shortStyle,
    lengthThreshold,
  }) => {

    const textClass = ((text != undefined) && (text.length > lengthThreshold)) ? longStyle : shortStyle;
    return (
      <div className={styles[textClass]} title={text}>
        {text}
      </div>
    );
  };

  const getQuestionnaireDetails = async (id) => {
    try {
      const response = await AOQuestionnaireRequest.get<Questionnaire>(
        `${AOQuestionnaireEndPoints.QuestionnaireList}/${id}`
      );
      dispatch(
        setBuilderValues(response.data.questions.questions_builder.form_values)
      );
      dispatch(
        setQuestionsMetadata(response.data.questions.questions_builder.meta)
      );
      dispatch(setPreviewerValues(response.data.questions.questions_previewer));
      dispatch(setId(response.data.id));
      dispatch(setQuestionVersion(response.data["version"]));
      navigate("/questionnaire-design/builder");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response.data);
        setError(error.response.data);
      } else {
        console.log(error);
        setError(
          "Corrupted data for the selected questionnaire id-" +
          id +
          ", please select another one. Error message: " +
          error.message
        );
      }
      setIsToShowBanner(true);
    }
  };

  const handleClick = async (card) => {
    getQuestionnaireDetails(card.id);
  };

  const onDownloadClick = async (card) => {
    try {
      const result = await getDownloadFile(
        card.id,
        card.name.replace(/ /g, "_")
      );
      console.log(result);
      if (axios.isAxiosError(result)) {
        console.log(result.response?.data);
        console.log(result.response?.status);
        setError(
          "Download for selected questionnaire id-" +
          card.id +
          " failed." +
          result.response?.data[0].message
        );
        setIsToShowBanner(true);
      }
    } catch (error) {
      // Handle unexpected errors
      setError(
        "Download for selected questionnaire id-" +
        card.id +
        " failed." +
        error.message
      );
      setIsToShowBanner(true);
    }
    {
      props.selectedQuestion === props.question.question_text;
    }
  };

  const IsChecked = (question) => {
    //console.log(e);
    console.log(question);
    return false;
  };

  return (
    <div>

      
      <div className={styles["search-cards-panel"]}>

        <WppBanner
          type="warning"
          show={isToShowBanner}
          closable
          onWppClose={handleBannerShowStateChange}
          className={styles["warning-banner"]}
        >
          {error}
        </WppBanner>

        <div>
          <div>
           
              <div >
                <WppCard
                  size="2xl"
                  interactive={true}
                  className={styles["search-question-card"]}
                >
                  <div>
                    <WppCheckbox
                      checked={props.IsChecked(props.question)} // Check if the question is selected
                      onWppChange={(e) => props.handleCheckboxChange(e, props.question)}
                      indeterminate={false}
                      required = {true}
                      labelConfig={{
                        text: props.question.question_label,
                      }}
                    />
                    <div className={styles["search-card"]}>
                        <WppTypography
                            type={"m-strong"}
                        >
                          {props.selectedQuestion}
                        </WppTypography>
                      <div >
                        {props.answerCode.map((value, index) => (
                          <div key={index}>
                            {props.type == "free-text" && (
                              <div style={{ display: "flex" }}>
                                <TextWithEllipsis
                                  text={value.ans_text}
                                  longStyle={"long-text-style"}
                                  shortStyle={"normal-text-style"}
                                  lengthThreshold={30}
                                ></TextWithEllipsis>
                              </div>
                            )}
                            {props.type != "free-text" && (
                              <div style={{ display: "flex", paddingLeft:"0.5rem" }}>
                                <WppTypography
                                    type={"s-strong"}
                                >
                                  {index<=2 &&(
                                    index+1.
                                  )} .
                                  
                                </WppTypography>
                              {index <=2 &&(
                                <TextWithEllipsis
                                text={value.ans_text}
                                longStyle={"long-text-style"}
                                shortStyle={"normal-text-style"}
                                lengthThreshold={30}
                              ></TextWithEllipsis>
                              )}
                                
                                 {index===3 &&(
                                     <WppTypography
                                         type={"s-strong"}
                                     >
                                       ..

                                     </WppTypography>
                                 )}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </WppCard>
              </div>
          </div>
        </div>
      </div>

    </div>
  );
};
