import React, {useState} from "react";
import {
    WppDivider,
    WppSpinner,
    WppNavSidebarItem,
    WppTypography,
    WppIconButton,
    WppIconNavigationMenu,
} from "@wppopen/components-library-react";
import DataProcessingWorkflow from "./DataProcessingWorkflow";
import {useNavigate} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {AudienceOriginRequest, EndPoints} from "../../../api/audience-origin";
import {ExportStatus, SurveySummary, SurveyWaveType} from "../../../types";
import DashboardBarChart from "./DashboardBarChart";
import {formatLabel, formattedDate} from "./utils";
import Sidepanel from "./Sidepanel";


function useSurveySummary() {
    return useQuery({
        queryKey: ["surveySummary"],
        queryFn: async () => {
            const {data} = await AudienceOriginRequest.post<SurveySummary[]>(
                EndPoints.SurveySummary
            );
            return data;
        },
    });
}

function useSurveyExportStatus() {
    return useQuery({
        queryKey: ["exportStatus"],
        queryFn: async () => {
            const {data} = await AudienceOriginRequest.post<ExportStatus[]>(
                EndPoints.ExportStatus
            );
            return data;
        },
    });
}

function getSurveyWavaData() {
    const {data, isLoading, error} = useQuery({
            queryKey: ["ao_survey"],
            queryFn: async () => {
                const {data} = await AudienceOriginRequest.post<SurveyWaveType[]>(
                    EndPoints.SurveyWaves
                );
                return data;
            },
        },
    );
    if (!isLoading && !error && data) {
        console.log("Survey Wave Data:", data);
    }
    localStorage.setItem("surveyWaveData", JSON.stringify(data));
    return {data, isLoading, error};
}

const DataProcessingDashboard = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [surveyWaveData, setSurveyWaveData] = useState<SurveyWaveType[] | null>(
        null
    );

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    const {data, error, status} = useSurveySummary();
    const {
        data: exportStatusData,
        error: exportStatusError,
        status: exportStatusStatus,
    } = useSurveyExportStatus();

    const {
        data: waveInfo,
        isLoading: waveIsLoading,
        error: waveError,
    } = getSurveyWavaData();


    const navigateToMetadataIngestion = () => {
        navigate("/data-processing/metadata-ingestion");
    };

    const navigateToRawDataIngestion = () => {
        navigate("/data-processing/raw-data-ingestion");
    };

    const navigateToAscriptionDataIngestion = () => {
        navigate("/data-processing/asc-data-ingestion");
    };

    const navigateToNetDataIngestion = () => {
        navigate("/data-processing/net-data-ingestion");
    };

    const navigateToWeightDataIngestion = () => {
        navigate("/data-processing/weight-data-ingestion");
    };

    const navigateToExport = () => {
        navigate("/data-processing/export-data");
    };

    const navigateToVariableOrder = () => {
        navigate("/data-processing/variable-order");
    };

    const getBarOptions = (
        exportStatusData: ExportStatus[],
        chartDataStatus: string
    ) => {
        let labels: string[] = [];
        let series = [];
        let legend = [];
        if (chartDataStatus === "success") {
            legend = Array.from(
                new Set([...exportStatusData.map((type) => type.export_type)])
            ).sort((a, b) => a.charCodeAt(0) - b.charCodeAt(0));
            const groupedData = exportStatusData.reduce<{
                [key: string]: ExportStatus[];
            }>((p, c) => {
                c.value = c.market_count;
                if (p[c.survey]) {
                    p[c.survey] = [...p[c.survey], c];
                } else {
                    p[c.survey] = [c];
                }

                return p;
            }, {});
            series = legend.map((l) => {
                return {
                    name: l,
                    type: "bar",
                    data: [],
                };
            });
            Object.entries(groupedData).forEach(([key, value]) => {
                labels.push(key);
                legend.forEach((type, idx) => {
                    series[idx].data.push(value.find((v) => v.export_type === type));
                });
            });
        }

        return {
            tooltip: {
                trigger: "item",
                formatter: function ({data}: { data: ExportStatus }) {
                    return `
                  Market Count: ${data.market_count} <br/>
                  Survey: ${data.survey} <br/>
                  Export Date: ${formattedDate(data.export_time)} <br />
                  `;
                },
            },
            responsive: true,
            maintainAspectRatio: false,

            xAxis: {
                type: "category",
                data: labels,
            },
            yAxis: {
                type: "value",
            },
            legend: {
                y: "bottom",
                show: true,
                orient: "horizontal",
                data: legend,
                formatter: function (d: string) {
                    return formatLabel(d);
                },
            },
            series,
        };
    };

    return (

        <div>

            <WppIconButton
                style={{
                    display: sidebarVisible ? "none" : "block",
                    marginLeft: "1rem",
                    marginTop: "2rem",
                    width: "3rem",
                    height: "3rem",
                    fontSize: "1.5rem",
                }}
                onClick={toggleSidebar}
            >
                <WppIconNavigationMenu/>
            </WppIconButton>

            <div
                style={{display: "grid", gridTemplateColumns: "0fr 1fr", gap: "1rem"}}
            >
                <div
                    id="sidebar"
                    style={{
                        width: "13rem",
                        borderRight: sidebarVisible ? "1px groove" : "none",
                        margin: "1rem 0",
                        marginLeft: "auto",
                        marginRight: "1rem",
                    }}
                >
                    <Sidepanel></Sidepanel>
                </div>
                <div
                    style={{
                        margin: 6,
                        verticalAlign: 1,
                    }}
                >
                    {status === "loading" ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <WppSpinner size="m"/>
                        </div>
                    ) : error ? (
                        <span>{error.toString() as any}</span>
                    ) : (

                        <DataProcessingWorkflow data={data ?? []}/>

                    )}
                </div>
            </div>

            <WppDivider/>
      

        </div>

    );
};

export default DataProcessingDashboard;
