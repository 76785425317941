import React, { useState } from "react";
import {
  WppTypography,
  WppTopbar,
  WppAvatar,
  WppMenuContext,
  WppListItem,
  WppIconAddCircle,
  WppIconLogout,
} from "@wppopen/components-library-react";
import { useNavigate } from "react-router-dom";
import AllureDockerSignOutDialog from "../AllureDockerSignOutDialog/AllureDockerSignOutDialog";
import AllureDockerNewProjectDialog from "../AllureDockerNewProjectDialog/AllureDockerNewProjectDialog";
import AllureDockerConfigDialog from "../AllureDockerConfigDialog/AllureDockerConfigDialog";
import styles from "./dashboard.module.css";
import { useLocation } from "react-router-dom";
import { BreadCrumb} from "./BreadCrumb";
import ErrorMessageComponent from './../../components/DataProcessing/Dashboard/ErrorMessageComponent'

const initNavigation = [
  {
    label: "Home",
    value: "/",
    path: "/",
  },
];

const breadcrumbLabels = {
  "/": "Home",
  "/data-processing": "Data Processing",
  "/data-processing/metadata-ingestion": "Metadata",
  "/data-processing/metadata-ingestion/new": "New Metadata Ingestion",
  "/data-processing/raw-data-ingestion": "Raw Data",
  "/data-processing/raw-data-ingestion/new": "New Raw Data Ingestion",
  "/data-processing/asc-data-ingestion": "Ascription Data",
  "/data-processing/asc-data-ingestion/new": "New Ascription Data Ingestion",
  "/data-processing/net-data-ingestion": "Net Variables",
  "/data-processing/net-data-ingestion/new-metadata-ingestion": "New Net Metadata Ingestion",
  "/data-processing/net-data-ingestion/new-net-var-calc-testrun": "New Test Run",
  "/data-processing/net-data-ingestion/new-net-data-ingestion": "New Net Variable Ingestion",
  "/data-processing/net-data-ingestion/new-net-var-calculation": "New Net Variable Calculation",
  "/data-processing/weight-data-ingestion": "Weight",
  "/data-processing/weight-data-ingestion/new": "New Weight Ingestion",
  "/data-processing/weight-data-ingestion/new-weight-calc-snowflake": "New Weight Calculation",
  "/data-processing/weight-data-ingestion/new-weight-calc-market": "New Weight Calculation Market File(s)",
  "/data-processing/weight-data-ingestion/new-weight-calc-global": "New Weight Calculation Global File",
  "/data-processing/export-data": "Export",
  "/data-processing/export-data/new-export-market": "New Export Market",
  "/data-processing/export-data/new-batch-export-market": "New Batch Export Market",
  "/data-processing/export-data/new-batch-export-market": "New Batch Export Market",
  "/data-processing/export-data/new-export-global": "New Export Global",
  "/data-processing/export-data/new-export-global-decoded": "New Export Global Decoded",
  "/data-processing/variable-order": "Variable Order",
  "/data-processing/variable-order/new": "Upload New File",
};

export const AppTopbar = () => {
  const [shouldShowSignoutDialog, setShouldShowSignoutDialog] = useState(false);
  const [value, setValue] = useState("/");
  const [shouldShowConfigDialog, setShouldShowConfigDialog] = useState(false);
  const [shouldShowNewProjectDialog, setShouldShowNewProjectDialog] =
    useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const currentNavLabels = pathnames.map((name, index) => {
    const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
    const label = breadcrumbLabels[routeTo] || name;
    return { label, value: routeTo, path: routeTo };
  });

  const finalNavLabels = [...initNavigation, ...currentNavLabels];
  const finalValue = finalNavLabels[finalNavLabels.length - 1].value;

  

  const handleTopbarItemChange = (event) => {
    setValue(event.detail.value);
    navigate(event.detail.path);
  };

  const handleLogout = () => {
    setShouldShowSignoutDialog(true);
  };

  const goToSwagger = () => {
    window.open(`${AppConfig.alluerDockerApiUrl}/swagger`, "_blank");
  };

  const TopBarRender = () => {
    if ((!finalNavLabels[1]) || (finalNavLabels[1].label.toLowerCase() != 'data processing')) {
      return (
        <WppTopbar
          key={new Date().getTime()}
          value={finalValue}
          navigation={finalNavLabels}
          onWppChange={handleTopbarItemChange}
          className={styles["app-header"]}
        >
          <div slot="app">
            <WppTypography
              type="m-body-accent"
              tag="h3"
              style={{ width: "6rem" }}
            >
              AO Portal
            </WppTypography>
          </div>

          <div slot="right" style={{ display: "flex", alignItems: "center" }}>
            <WppMenuContext>
              <WppAvatar
                slot="trigger-element"
                name="User Name"
                tooltipPlacement="bottom"
                withTooltip
                src=""
                size="m"
              />
              <div>
                <WppListItem
                  selectable
                  active={false}
                  checked={false}
                  highlight={false}
                  onWppChangeListItem={goToSwagger}
                >
                  <span slot="label">Swagger UI</span>
                  <WppIconAddCircle slot="left" />
                </WppListItem>
                <WppListItem
                  selectable
                  active={false}
                  checked={false}
                  highlight={false}
                  onWppChangeListItem={handleLogout}
                >
                  <span slot="label">Logout</span>
                  <WppIconLogout slot="left" />
                </WppListItem>
              </div>
            </WppMenuContext>
          </div>

          <AllureDockerNewProjectDialog
            open={shouldShowNewProjectDialog}
            closeNewProjectDialog={() => setShouldShowNewProjectDialog(false)}
            getProjects={() => { }}
          />
          <AllureDockerConfigDialog
            open={shouldShowConfigDialog}
            closeConfigDialog={() => setShouldShowConfigDialog(false)}
          />
          <AllureDockerSignOutDialog
            open={shouldShowSignoutDialog}
            closeSignOutDialog={() => setShouldShowSignoutDialog(false)}
          />
        </WppTopbar>
      )
    } else {
      return (
        <div>
          <div>
            <WppTopbar
              key={new Date().getTime()}
              value={finalValue}
              navigation={initNavigation}
              onWppChange={handleTopbarItemChange}
              className={styles["app-header"]}
            >
              <div slot="app">
                <WppTypography
                  type="m-body-accent"
                  tag="h3"
                  style={{ width: "6rem" }}
                >
                  AO Portal
                </WppTypography>
              </div>

              <div slot="right" style={{ display: "flex", alignItems: "center" }}>
                <WppMenuContext>
                  <WppAvatar
                    slot="trigger-element"
                    name="User Name"
                    tooltipPlacement="bottom"
                    withTooltip
                    src=""
                    size="m"
                  />
                  <div>
                    <WppListItem
                      selectable
                      active={false}
                      checked={false}
                      highlight={false}
                      onWppChangeListItem={goToSwagger}
                    >
                      <span slot="label">Swagger UI</span>
                      <WppIconAddCircle slot="left" />
                    </WppListItem>
                    <WppListItem
                      selectable
                      active={false}
                      checked={false}
                      highlight={false}
                      onWppChangeListItem={handleLogout}
                    >
                      <span slot="label">Logout</span>
                      <WppIconLogout slot="left" />
                    </WppListItem>
                  </div>
                </WppMenuContext>
              </div>

              <AllureDockerNewProjectDialog
                open={shouldShowNewProjectDialog}
                closeNewProjectDialog={() => setShouldShowNewProjectDialog(false)}
                getProjects={() => { }}
              />
              <AllureDockerConfigDialog
                open={shouldShowConfigDialog}
                closeConfigDialog={() => setShouldShowConfigDialog(false)}
              />
              <AllureDockerSignOutDialog
                open={shouldShowSignoutDialog}
                closeSignOutDialog={() => setShouldShowSignoutDialog(false)}
              />
              


            </WppTopbar>
            <div className="hi" >
             <h1 >
              hi
             </h1>
             <BreadCrumb items={currentNavLabels} />
             
            </div>
           
          </div>
          
          <div>
          </div>
         
        </div>
        
      )

    }
  }

  return (
    <>
      {TopBarRender()}
    </>
  );
};
