import React, {useState} from "react";
import {
    WppStepper,
    WppStep,
    WppTopbar,
    WppButton,
    WppTypography,
} from "@wppopen/components-library-react";
import {useNavigate} from "react-router-dom";
import styles from "../Dashboard/data-ingestion-workflow.module.scss";
import {InstructionStep} from "../Common/InstructionStep";
import {RawDagConstants} from "./RawDagConstants";
import {UploadFilesStep} from "../Common/UploadFilesStep";
import {TriggerDagStep} from "../Common/TriggerDagStep";
import {FileNamingConvStep} from "../Common/FileNamingConvStep";

const WORKFLOW_STEPS = 4;

export const RawDataIngestionWorkflow = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [highestStep, setHighestStep] = useState(1);
    const [isDagSuccess, setIsDagSuccess] = useState(false);
    const [fileNamingConventionStepChecked, setFileNamingConventionStepChecked] =
        useState(false);
    const [instructionStepChecked, setInstructionStepChecked] =
        useState(false);
    const [
        isInstructionStepPristine,
        setIsInstructionStepPristine,
    ] = useState(true);
    const [
        isFileNamingConventionStepPristine,
        setIsFileNamingConventionStepPristine,
    ] = useState(true);
    const [uploadFilesStepChecked, setUploadFilesStepChecked] = useState(false);
    const [isUploadFilesStepPristine, setUploadFilesStepPristine] =
        useState(true);

    const handleNextStep = () => {
        if (currentStep === WORKFLOW_STEPS) {
            return;
        }
        if (currentStep === 1) {
            setIsInstructionStepPristine(false);

            if (!instructionStepChecked) {
                return;
            }
        }
        if (currentStep === 2) {
            setIsFileNamingConventionStepPristine(false);

            if (!fileNamingConventionStepChecked) {
                return;
            }
        }
        if (currentStep === 3) {
            setUploadFilesStepPristine(false);

            if (!uploadFilesStepChecked) {
                return;
            }
        }

        const step = currentStep + 1;
        setCurrentStep(step);
        if (step > highestStep) {
            setHighestStep(step);
        }
    };
    const handlePreviousStep = () => {
        if (currentStep === 1) {
            return;
        }

        setCurrentStep(currentStep - 1);
    };

    const onDagSuccess = () => {
        setIsDagSuccess(true);
    };

    const getSelectedPage = () => {
        switch (currentStep) {
            case 1:
                return (
                    <InstructionStep
                        checked={instructionStepChecked}
                        onChange={setInstructionStepChecked}
                        title="Please read the instructions below to proceed with the Raw Fact Data Ingestion Run:"
                        context={["This is used to upload raw data in snowflake DB.",
                            "Please make sure the metadata (variable definitions) are loaded in snowflake using metadata run before running this DAG.",
                            "This pipeline will upload data to the key value table ao_schema.ao_survey_file_answer",
                            "Please upload files for multiple markets together in one run (one batch can process 15-25 files)",
                        ]}
                        checkboxText="I have read and understood the instructions."
                        links={[]}
                    />
                );
            case 2:
                return (
                    <FileNamingConvStep
                        checked={fileNamingConventionStepChecked}
                        onChange={setFileNamingConventionStepChecked}
                        configName={RawDagConstants.ingestionFileNamingConvention}
                    />
                );
            case 3:
                return (
                    <UploadFilesStep
                        checked={uploadFilesStepChecked}
                        onChange={setUploadFilesStepChecked}
                        title="Upload Raw SPSS Files To Snowflake DB:"
                        links={[
                            {
                                label: "Confluence Wiki",
                                link: RawDagConstants.rawWikiLink,
                                context: "Find detailed instruction for raw file ingestion here",
                            },
                            {
                                label: "GCS Location",
                                link: RawDagConstants.rawDataUploadUrl,
                                context: "Use File Upload Widget below or clean folder and upload prepared file directly here",
                            }
                        ]}
                        checkboxText="I have uploaded the config files."
                    />
                );
            case 4:
                return (
                    <TriggerDagStep
                        onDagSuccess={onDagSuccess}
                        configName={RawDagConstants.TriggerRawIngestionDAG}
                        survey=''
                    />
                );
        }
        return (
            <div>
                <h3>Page {currentStep}</h3>
            </div>
        );
    };

    const handleStepClick = (event) => {
        if (isDagSuccess) {
            return;
        }

        const step = event.detail.index;
        if (step <= highestStep) {
            setCurrentStep(step);
        }
    };

    const navigateToMIHome = () => {
        navigate("/data-processing/raw-data-ingestion");
    };

    return (
        <div>
            <WppTopbar navigation={[]} style={{margin: "1rem"}}>
                <div slot="app">
                    <WppTypography type="l-midi" style={{width: "20rem"}}>
                        New Raw Data Ingestion
                    </WppTypography>
                </div>
            </WppTopbar>
            <div
                style={{
                    display: "flex",
                    margin: "2rem",
                }}
            >
                <WppStepper activeStep={currentStep} style={{width: "19rem"}}>
                    <WppStep
                        onWppStepChange={handleStepClick}
                        error={
                            !instructionStepChecked &&
                            !isInstructionStepPristine
                        }
                    >
                        <p slot="label">Instructions</p>
                    </WppStep>
                    <WppStep
                        onWppStepChange={handleStepClick}
                        error={
                            !fileNamingConventionStepChecked &&
                            !isFileNamingConventionStepPristine
                        }
                    >
                        <p slot="label">File Name</p>
                    </WppStep>
                    <WppStep
                        onWppStepChange={handleStepClick}
                        error={!uploadFilesStepChecked && !isUploadFilesStepPristine}
                    >
                        {" "}
                        <p slot="label">Upload Files</p>
                    </WppStep>
                    <WppStep onWppStepChange={handleStepClick}>
                        <p slot="label">Trigger DAG</p>
                    </WppStep>
                </WppStepper>
                <div className={styles["stepper-container"]}>{getSelectedPage()}</div>
            </div>

            <div
                style={{
                    display: "flex",
                    margin: "2rem",
                    justifyContent: "flex-end",
                }}
            >
                {isDagSuccess ? (
                    <WppButton variant="secondary" onClick={navigateToMIHome}>
                        Go To Raw Data Ingestion Home
                    </WppButton>
                ) : (
                    <>
                        <WppButton
                            variant="secondary"
                            onClick={handlePreviousStep}
                            disabled={isDagSuccess}
                            style={{marginRight: "1rem"}}
                        >
                            Previous Step
                        </WppButton>
                        <WppButton onClick={handleNextStep} disabled={isDagSuccess}>
                            Next Step
                        </WppButton>
                    </>
                )}
            </div>
        </div>
    );
};
