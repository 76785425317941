import React, {useContext} from "react";
import {
    WppSelect,
    WppListItem,
} from "@wppopen/components-library-react";


const SPEC_FILE_TEMPLATES = [
    {
        name: "AO23W1",
        value: "AO23W1",
    },
    {
        name: "AO23W2",
        value: "AO23W2",
    },
    {
        name: "AO23W3",
        value: "AO23W3",
    },
    {
        name: "AO23W4",
        value: "AO23W4",
    },
    {
        name: "AO22W1",
        value: "AO22W1",
    },
    {
        name: "AO22W2",
        value: "AO22W2",
    },
    {
        name: "AO22W3",
        value: "AO22W3",
    },
    {
        name: "AO22W4",
        value: "AO22W4",
    },
];

type Props = {
    value: string;
    onValueChange: (value: string) => void;
};


export const SurveySelectStep = (props: Props) => {
    var formattedData = [], data;
    const storedData = localStorage.getItem('surveyWaveData');
    if (storedData && typeof storedData === 'string') {
        data = JSON.parse(storedData);
    }
    if (data) {
        formattedData = data.map(survey => ({
            name: survey.SURVEY_NAME,
            value: survey.SURVEY_NAME
        }));
        formattedData = Array.from(new Set(formattedData.map(item => JSON.stringify(item))))
            .map(item => JSON.parse(item));
    }
    const handleChange = (event: CustomEvent) => {
        props.onValueChange(event.detail.value);
    };

    return (
        <WppSelect
            style={{
                width: "50%",
                height: "60%"
            }}
            onWppChange={handleChange}
            placeholder="Please select a survey name"
            value={props.value}
            labelConfig={{text: "Survey Name"}}
            message={props.value ? "" : "Please select a survey name to proceed"}
            required
        >
            {formattedData.map((template) => (
                <WppListItem value={template.value} key={template.value}>
                    <p slot="label">{template.name}</p>
                </WppListItem>
            ))}

        </WppSelect>
    );
};
