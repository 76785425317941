import React, { useState, useEffect } from "react";

import {
  createSchemaField,
  useField,
  useFieldSchema,
  observer,
} from "@formily/react";
import { Button } from "antd";
import {
  Input,
  Space,
  Select,
  FormItem,
  Radio,
  FormGrid,
} from "@formily/antd-v5";

import {
  WppButton,
  WppCard,
  WppSideModal,
  WppIconCompose,
  WppIconPlusCircle,
  WppIconTrash,
  WppInput,
  WppIconCross,
  WppTextareaInput,
  WppCheckbox,
  WppRadio,
  WppTypography,
} from "@wppopen/components-library-react";
import styles from "./Question.module.css";
import "ag-grid-community/styles/ag-grid.css";
import "@wppopen/components-library/dist/collection/ag-theme-wpp.css";
import AnswerGridLayout from "./AnswerGridLayout";
import QuestionType from "./QuestionType";

type QuestionProps = {
  id: string;
  type: string;
  order: number;
  handleDelete: (id: string) => void;
  handleClearField: (field: string, index: number) => void;
  handleChange?: (field: string, index: number, value: string) => void;
  form: any;
};

const QuestionTypeSection = ({ questionType }) => {
  switch (questionType) {
    case QuestionType.Single:
      return (
        <WppRadio
          checked={true}
          style={{ pointerEvents: "none", marginRight: "10px" }}
        ></WppRadio>
      );
    case QuestionType.Multi:
      return (
        <WppCheckbox
          checked={true}
          style={{ pointerEvents: "none", marginRight: "10px" }}
        ></WppCheckbox>
      );
    default:
      return <div />;
  }
};

const GenericQuestion = (props: QuestionProps) => {
  const questionType = props.id + "|type";
  const questionOrder = props.id + "|sequence";
  const questionText = props.id + "|question_text";
  const questionCode = props.id + "|question_label";
  const questionSection = props.id + "|section";
  const questionFilter = props.id + "|filter";
  const questionComment = props.id + "|comment";
  const questionCategory = props.id + "|category";
  const questionDirection = props.id + "|direction_text";
  const questionValidation = props.id + "|validation";

  const answerId = props.id + "|answers";
  const answerTextId = props.id + "|ans_text";
  const answerCodeId = props.id + "|ans_code";
  const answerFilter = props.id + "|ans_filter";
  const answerComment = props.id + "|ans_comment";

  const [isModalOpen, setModalStatus] = useState(false);

  const handleOpenModal = () => setModalStatus(true);
  const handleCloseModal = () => setModalStatus(false);

  // added observers with check boxes for free text and single coded per row
  const Answers = observer((props: any) => {
    const field: any = useField();
    const schema: any = useFieldSchema();

    const [tableQuestionData, setTableQuestionData] = useState([]);
    const [tableAnswerData, setTableAnswerData] = useState([]);

    useEffect(() => {
      if (
        (props.questionType === QuestionType.OpenText ||
          props.questionType === QuestionType.OpenNumeric ||
          props.questionType === QuestionType.GridSingleText) &&
        field.value.length === 0
      ) {
        field.value.push({ ans_sequence: field.value.length + 1 });
      }

      if (props.form.values[answerId].length) {
        if (tableQuestionData.length === 0) {
          setTableQuestionData(
            props.form.values[answerId]?.filter((v) => v.ans_type === "ROW") ||
              []
          );
        }
        if (tableAnswerData.length === 0) {
          setTableAnswerData(
            props.form.values[answerId]?.filter(
              (v) => v.ans_type === "COLUMN"
            ) || []
          );
        }
      }
    }, []);

    return (
      <div>
        {props.questionType === QuestionType.GridNumeric ? (
          <div>
            <div style={{ width: "100%" }}>
              <p style={{ fontSize: "14px" }}>Question data</p>
              <AnswerGridLayout
                tableData={tableQuestionData}
                type="ROW"
                setData={(e) => {
                  setTableQuestionData(e);
                  let data = [...props.form.values[answerId]];
                  data = [...tableAnswerData, ...e];
                  props.form.setValuesIn(answerId, data);
                }}
              />
            </div>
          </div>
        ) : props.questionType === QuestionType.GridSingleText ? (
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <p style={{ fontSize: "14px" }}>Question data</p>

              <AnswerGridLayout
                tableData={tableQuestionData}
                type="ROW"
                setData={(e) => {
                  setTableQuestionData(e);
                  let data = [...props.form.values[answerId]];
                  data = [...tableAnswerData, ...e];
                  props.form.setValuesIn(answerId, data);
                }}
              />
            </div>

            <div style={{ width: "100%" }}>
              <p style={{ fontSize: "14px" }}>Answer data</p>

              <AnswerGridLayout
                tableData={tableAnswerData}
                type="COLUMN"
                setData={(e) => {
                  setTableAnswerData(e);
                  let data = [...props.form.values[answerId]];
                  data = [...tableQuestionData, ...e];
                  props.form.setValuesIn(answerId, data);
                }}
              />
            </div>
          </div>
        ) : (
          field.value &&
          field.value?.map((item, index) => {
            return (
              <div key={index} style={{ marginBottom: 10 }}>
                <Space>
                  <QuestionTypeSection
                    questionType={props.questionType}
                  ></QuestionTypeSection>
                  <SchemaField
                    x-component="FormGrid"
                    x-component-props={{
                      maxColumns: 3,
                      minColumns: 2,
                    }}
                    name={index}
                  >
                    <SchemaField.Void x-component="Space">
                      <SchemaField.String
                        name={answerTextId}
                        title="Answer Text"
                        x-decorator={FormItem}
                        required={true}
                        x-decorator-props={{ gridSpan: 3 }}
                        x-component="WppInput"
                        x-component-props={{
                          name: answerTextId,
                          title: "Answer Text",
                          key: index,
                          required: true,
                          style: {
                            width: "400px",
                          },
                          onWppChange: (e) => {
                            props.handleChange(
                              answerTextId,
                              index,
                              e.target.value
                            );
                          },
                          children: (
                            <WppIconCross
                              slot="icon-end"
                              aria-label="Clear icon"
                              onClick={() => {
                                props.handleClearField(answerTextId, index);
                              }}
                            />
                          ),
                        }}
                        x-hidden={
                          props.type === QuestionType.OpenText ||
                          props.type === QuestionType.OpenNumeric
                        }
                      />
                      <SchemaField.String
                        name={answerCodeId}
                        title="Answer Code"
                        required={true}
                        x-decorator={FormItem}
                        x-component="WppInput"
                        x-component-props={{
                          name: answerTextId,
                          title: "Answer Text",
                          key: index,
                          style: {
                            width: "180px",
                          },
                          onWppChange: (e) => {
                            props.handleChange(
                              answerCodeId,
                              index,
                              e.target.value
                            );
                          },
                          children: (
                            <WppIconCross
                              slot="icon-end"
                              aria-label="Clear icon"
                              onClick={() => {
                                props.handleClearField(answerCodeId, index);
                              }}
                            />
                          ),
                        }}
                        x-hidden={
                          props.type === QuestionType.OpenText ||
                          props.type === QuestionType.OpenNumeric
                        }
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="Space"
                      x-component-props={{ size: "large" }}
                    >
                      <SchemaField.String
                        name={answerFilter}
                        title="Answer Filter"
                        x-decorator={FormItem}
                        x-component="WppTextareaInput"
                        x-component-props={{
                          name: answerFilter,
                          title: "Answer Filter",
                          key: index,
                          rows: 2,
                          style: {
                            width: "400px",
                          },
                          onWppChange: (e) => {
                            props.handleChange(
                              answerFilter,
                              index,
                              e.target.value
                            );
                          },
                        }}
                        x-decorator-props={{ width: "400px" }}
                      />
                      <SchemaField.String
                        name={answerComment}
                        title="Answer Comment"
                        x-decorator={FormItem}
                        x-component="WppTextareaInput"
                        x-component-props={{
                          name: answerComment,
                          title: "Answer Comment",
                          key: index,
                          rows: 2,
                          style: {
                            width: "180px",
                          },
                          onWppChange: (e) => {
                            props.handleChange(
                              answerComment,
                              index,
                              e.target.value
                            );
                          },
                        }}
                        x-decorator-props={{ gridSpan: 2 }}
                      />
                    </SchemaField.Void>
                  </SchemaField>

                  {/* <RecursionField schema={schema.items} name={index} /> */}

                  {props.questionType === QuestionType.Multi ||
                  props.questionType === QuestionType.Single ? (
                    <Button
                      shape="circle"
                      icon={<WppIconTrash />}
                      onClick={() => {
                        //field.remove(index);
                        field.value.splice(index, 1);
                        field.value = field.value.map((v, index) => {
                          return { ...v, ans_sequence: index + 1 };
                        });
                      }}
                    ></Button>
                  ) : null}
                </Space>
              </div>
            );
          })
        )}
        {props.questionType === QuestionType.Multi ||
        props.questionType === QuestionType.Single ? (
          <Button
            icon={<WppIconPlusCircle />}
            onClick={() => {
              field.push({ ans_sequence: field.value.length + 1 });
            }}
          ></Button>
        ) : null}
      </div>
    );
  });

  const SchemaField = createSchemaField({
    components: {
      Answers,
      Input,
      Select,
      FormItem,
      Radio,
      FormGrid,
      WppInput,
      WppTextareaInput,
      Space,
    },
  });

  return (
    <div>
      <WppCard className={styles["card-container"]}>
        <div className={styles["question-row-container"]}>
          <WppTypography
            type="s-body"
            style={{
              padding: "6px",
              border: "2px dashed rgba(112, 76, 182, 0.4)",
              borderRadius: "8px",
            }}
          >
            {props.type}
          </WppTypography>
          <div className={styles["question-modal-button-container"]}>
            <Button
              icon={<WppIconTrash />}
              className={"wpp-spacing-8-right"}
              onClick={() => props.handleDelete(props.id)}
              style={{ marginLeft: "auto" }}
            ></Button>
            <Button
              icon={<WppIconCompose />}
              onClick={handleOpenModal}
              style={{ marginLeft: "auto" }}
            ></Button>
          </div>
        </div>
        <div className={styles["card"]}>
          <SchemaField>
            <SchemaField.String
              name={questionOrder}
              x-component="WppInput"
              x-hidden={true}
              default={props.order}
            />
            <SchemaField.String
              name={questionType}
              x-hidden={true}
              x-component="WppInput"
              default={props.type}
            />
            <SchemaField.String
              name={questionText}
              title={"Question Text"}
              required={true}
              x-component="WppInput"
              x-decorator={FormItem}
              x-component-props={{
                name: questionText,
                title: "Question Text",
                onWppChange: (e) => {
                  props.form.setValuesIn(questionText, e.target.value);
                },
                children: (
                  <WppIconCross
                    slot="icon-end"
                    aria-label="Clear icon"
                    onClick={() => {
                      props.form.setValuesIn(questionText, "");
                    }}
                  />
                ),
              }}
            />
            <SchemaField.String
              name={questionCode}
              title={"Question Code"}
              required={true}
              x-component="WppInput"
              x-decorator={FormItem}
              x-component-props={{
                name: questionCode,
                title: "Question Code",
                onWppChange: (e) => {
                  props.form.setValuesIn(questionCode, e.target.value);
                },
                children: (
                  <WppIconCross
                    slot="icon-end"
                    aria-label="Clear icon"
                    onClick={() => {
                      props.form.setValuesIn(questionCode, "");
                    }}
                  />
                ),
              }}
            />
            <p>Answers</p>
            <SchemaField.Array
              name={answerId}
              x-component="Answers"
              x-component-props={{ questionType: props.type, ...props }}
            />
          </SchemaField>
         
        </div>

        <WppSideModal
          open={isModalOpen}
          onWppSideModalClose={handleCloseModal}
          onWppSideModalOpen={handleOpenModal}
          className={styles["question-modal"]}
        >
          <div slot="body">
            <SchemaField>
              <SchemaField.Number
                name={questionCategory}
                title="Category"
                x-decorator={FormItem}
                x-component="Select"
                enum={[
                  { label: "Screener", value: "SCREENER" },
                  { label: "Demographics", value: "DEMOGRAPHICS" },
                  { label: "Others", value: "OTHERS" },
                ]}
                x-component-props={{
                  allowClear: true,
                  style: {
                    width: "60%",
                  },
                }}
              />
              <SchemaField.Number
                name={questionSection}
                title="Section"
                x-decorator={FormItem}
                x-component="Select"
                enum={[
                  { label: "Core", value: "CORE" },
                  { label: "Plus", value: "PLUS" },
                  { label: "Tools", value: "TOOLS" },
                ]}
                x-component-props={{
                  allowClear: true,
                  style: {
                    width: "60%",
                  },
                }}
              />
              <SchemaField.String
                name={questionFilter}
                title={"Filter"}
                x-component="Input"
                x-decorator={FormItem}
              />
              <SchemaField.String
                name={questionValidation}
                title="Validation"
                x-decorator={FormItem}
                x-component="WppTextareaInput"
                x-decorator-props={{
                  tooltip:
                    "Supported Rules Examples: v > 10; v < 24; 0 < v < 30; sum < 24;",
                  tooltipLayout: "icon",
                }}
                x-hidden={
                  props.type != QuestionType.GridNumeric &&
                  props.type != QuestionType.OpenNumeric
                }
                x-component-props={{
                  name: questionValidation,
                  title: "Validation",
                  key: questionValidation,
                  rows: 2,
                  style: {
                    width: "200px",
                  },
                  onWppChange: (e) => {
                    props.handleChange(questionValidation, 0, e.target.value);
                  },
                }}
              />
              {/*<SchemaField.String*/}
              {/*    name={questionDirection}*/}
              {/*    title={"Direction Text"}*/}
              {/*    x-component="Input.TextArea"*/}
              {/*    x-decorator={FormItem}*/}
              {/*/>*/}
              <SchemaField.String
                name={questionDirection}
                title="Direction Text"
                x-decorator={FormItem}
                x-component="WppTextareaInput"
                x-component-props={{
                  name: questionDirection,
                  title: "Direction Text",
                  key: questionDirection,
                  rows: 2,
                  style: {
                    width: "200px",
                  },
                  onWppChange: (e) => {
                    props.handleChange(questionDirection, 0, e.target.value);
                  },
                }}
                
              />
              
              <SchemaField.String
                name={questionComment}
                title="Comment"
                x-decorator={FormItem}
                x-component="WppTextareaInput"
                x-component-props={{
                  name: questionComment,
                  title: "Comment",
                  key: questionComment,
                  rows: 2,
                  style: {
                    width: "200px",
                  },
                  onWppChange: (e) => {
                    props.handleChange(questionComment, 0, e.target.value);
                  },
                }}
               
              />
            </SchemaField>
          </div>
          <div slot="actions">
            <WppButton variant="secondary" size="s" onClick={handleCloseModal}>
              Close
            </WppButton>
          </div>
        </WppSideModal>
      </WppCard>
    </div>
  );
};

export default GenericQuestion;
