import {
  WppProgressIndicator,
  WppSkeleton,
} from "@wppopen/components-library-react";
import React from "react";
import styles from "./Question.module.css";

const SkeletonCard = () => {
  return (
    <div className={styles["skeleton-card"]}>
      <WppSkeleton variant="rectangle" width="100%" height="100%" />
    </div>
  );
};

export const SkeletonCardScreen = () => (
  <div className={styles["skeleton-screen"]}>
    <WppSkeleton variant="rectangle" width="400px" height="50px" />
    <div className={styles["skeleton-row"]}>
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
    </div>
    <div className={styles["skeleton-row"]}>
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
    </div>
  </div>
);

export const SkeletonRow = () => (
  <div className={styles["skeleton-row-container"]}>
    <div className={styles["skeleton-row"]}>
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
    </div>
  </div>
);
