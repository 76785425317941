import React, {useState} from "react";
import {
    WppListItem,
    WppAutocomplete
} from "@wppopen/components-library-react";
import {AutocompleteDefaultOption} from "@wppopen/components-library";


type Props = {
    value: AutocompleteDefaultOption[];
    onValueChange: (value: AutocompleteDefaultOption[]) => void;
};


export const AddSurveyStep = (props: Props) => {

    const [messageType, setMessageType] = useState(undefined);
    const [warningMessage, setWarningMessage] = useState("");

    const storedData = localStorage.getItem('surveyWaveData');
    let autoCompleteData = [], autoData;
    

    if (storedData && typeof storedData === 'string') {
        autoData = JSON.parse(storedData);
    }

    if (autoData) {
        autoCompleteData = autoData.map((survey, index) => {
            return {
                id: index,
                label: survey.SURVEY_NAME
            }
        });
        console.log(autoCompleteData+"surveyData");
    }

    const handleNewSurveyCreation = (event: CustomEvent) => {
        const newSurvey = event.detail.toUpperCase();
        const regex = /^AO\d{2}(W\d+|.*W\d+).*$/;
        const isValid = regex.test(newSurvey);
        if (!isValid) {
            props.onValueChange([]);
            setMessageType("error");
            setWarningMessage("Invalid survey name. Please enter a valid survey name");
        } else {
            setWarningMessage("")
            setMessageType(undefined);
            const newOption: AutocompleteDefaultOption = {
                id: Math.random(),
                label: newSurvey,
            };
            props.onValueChange([newOption]);


        }
    };

    return (
        <div>
            <WppAutocomplete
                required={true}
                name="basic"
                type="regular"
                labelConfig={{
                    icon: 'wpp-icon-info',
                    text: 'Select or create a survey:',
                    description: 'e.g: AO23W1, AO23OFFLINEW2, AO23TEENW3'
                }}
                locales={{
                    createNewElement: 'Create new survey',
                    nothingFound: 'No survey found',
                    more: 'More',
                    selected: count => `${count} selected`,
                    loading: 'Loading...',
                }}
                placeholder="Select survey"
                value={props.value}
                onWppChange={(e) => {
                    setMessageType(undefined);
                    setWarningMessage("");
                    props.onValueChange(e.detail.value);
                }}
                data-testid="basic-autocomplete"
                multiple={false}
                showCreateNewElement
                simpleSearch
                message={warningMessage}
                messageType={messageType}
                onWppCreateNewOption={handleNewSurveyCreation}>
                {autoCompleteData.map(option => (
                    <WppListItem key={option.id} value={option} label={option.label}>
                        <p slot="label">{option.label}</p>
                    </WppListItem>
                ))}
            </WppAutocomplete>
        </div>
    );
};
