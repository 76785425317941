import React, { useState } from "react";
import {
    WppInput,
    WppSelect,
    WppTypography,
    WppButton,
    WppListItem,
    WppTooltip,
    WppIconHelp
} from "@wppopen/components-library-react";
import { AudienceOriginRequest, EndPoints } from "../../api/audience-origin";
import { useCookies } from "react-cookie";
import axios, { AxiosRequestConfig } from "axios";
import { AppConfig } from "../../AppConfig";

type Props ={
    testSuite: string;
    projectId: string;
    onDagSuccess: () => void;
};

export const TriggerDag = (props: Props) => {
    const [surveys, setSurveys] = useState("");
    const [useAscription, setUseAscription] = useState(null);
    const [status, setStatus] = useState("-");
    const [dagId, setDagId] = useState("");
    const [dagRunId, setDagRunId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);

    const [cookies] = useCookies([ 'csrf_access_token']);

    console.log(cookies);
    const csrfToken = cookies.csrf_access_token;
    console.log(csrfToken);
    const accessToken =  localStorage.getItem("access_token");
    const triggerExternalApi = async (datconfig1, csrfToken, accessToken) => {
        try {
            const config: AxiosRequestConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken, 
                  },
                  withCredentials: true,  
                };

            const apiUrl = `${AppConfig.alluerDockerApiUrl}/projects`;
            console.log(apiUrl);
            await axios.post(apiUrl, datconfig1, config);
        } catch (error) {
            console.error("Error triggering external API:", error);
        }

    };

    const handleInputChange = async () => {
        if (!surveys || !props.testSuite || !props.projectId || useAscription === null) {
            setErrorMessage("Please fill out all required fields.");
            setHasError(true);
            return;
        }

        const datconfig1 = {
            id: props.projectId
            
        };

        const datconfig = {
            surveys,
            test_suite: props.testSuite,
            project_id: props.projectId,
            use_ascription: useAscription
        };

        const dagName = "ao_qa";

        try {
            setHasError(false);
            setIsLoading(true);

            const [triggerStatusResponse, apiResponse] = await Promise.all([
                AudienceOriginRequest.post(
                    `${EndPoints.RawDataTriggerDAG}/${dagName}`,
                    datconfig,
                    { headers: { "Content-Type": "application/json" } }
                ),
                triggerExternalApi(datconfig1, csrfToken, accessToken)
            ]);

            setStatus(triggerStatusResponse.data.state);
            setDagId(triggerStatusResponse.data.dagId);
            setDagRunId(triggerStatusResponse.data.dagRunId);
            props.onDagSuccess();

        } catch (error) {
            setErrorMessage(error.response?.data?.message ?? error.message);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAscriptionChange = (e) => {
        setUseAscription(e.target.value === "true");
    };

    const handleChange = (e) => {
        setSurveys(e.target.value);
    };

    const handleButtonClick = () => {
        handleInputChange();
    };

    const metadataAirflowLogs = AppConfig.airflowBaseUrl + "/ao_qa/graph"

    return (
        <div>
            {status === "-" ? (
                                  <div style={{ display: "table-caption", alignItems: "center" }}>

                    <div style={{ display: "flex", alignItems: "center" }}>
                        <label style={{ marginRight: "0.5rem" }}>Survey name</label>
                        <WppTooltip text="Enter the name of the survey.">
                            <WppIconHelp style={{ marginLeft: "0.25rem", cursor: "pointer" }} />
                        </WppTooltip>
                        <WppInput value={surveys} onWppChange={handleChange} style={{ flex: "87%", marginLeft: "0.5rem" }} />
                    </div>

                    <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
                        <label style={{ marginRight: "0.5rem" }}>Project ID        </label>
                        <WppTooltip text="Project identifier for tracking.">
                            <WppIconHelp style={{ marginLeft: "0.25rem", cursor: "pointer" }} />
                        </WppTooltip>
                        <WppInput value={props.projectId} style={{ flex: 1, marginLeft: "0.5rem" }} />
                    </div>

                    <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
                        <label style={{ marginRight: "0.5rem" }}>Test Suite</label>
                        <WppTooltip text="Test suite for the current project.">
                            <WppIconHelp style={{ marginLeft: "0.25rem", cursor: "pointer" }} />
                        </WppTooltip>
                        <WppInput value={props.testSuite} style={{ flex: 1, marginLeft: "0.5rem" }} />
                    </div>

                    <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
                        <label style={{ marginRight: "0.5rem" }}>Use Ascription</label>
                        <WppTooltip text="Specify whether to use ascription for this survey.">
                            <WppIconHelp style={{ marginLeft: "0.25rem", cursor: "pointer" }} />
                        </WppTooltip>
                        <WppSelect
                            onWppChange={handleAscriptionChange}
                            placeholder="use_ascription"
                            value={useAscription === null ? "" : useAscription ? "true" : "false"}
                            style={{ marginLeft: "0.5rem", flex: 1 }}
                        >
                            <WppListItem value="true">
                                <p slot="label">True</p>
                            </WppListItem>
                            <WppListItem value="false">
                                <p slot="label">False</p>
                            </WppListItem>
                        </WppSelect>
                    </div>

                    <WppButton onClick={handleButtonClick} style={{ marginTop: "1rem" }}>
                        {isLoading ? "Triggering DAG..." : "Trigger DAG"}
                    </WppButton>

                    {hasError && (
                        <p style={{ color: "red", marginTop: "0.5rem" }}>
                            {errorMessage}
                        </p>
                    )}
                </div>
            ) : (
                <WppTypography type="l-body">
                    <ul>
                        <li>Status of DAG: {status}</li>
                        <li>DAG Id: {dagId}</li>
                        <li>DAG Run Id: {dagRunId}</li>
                        <li>
                            Link for airflow UI where user can check logs
                            <ul>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={metadataAirflowLogs}
                                    >
                                        Click Here
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </WppTypography>
            )}
        </div>
    );
};