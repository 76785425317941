import React, {useState} from "react";
import styled from "@emotion/styled";
import {
    WppTopbar,
    WppButton,
    WppTypography,
} from "@wppopen/components-library-react";
import {useNavigate} from "react-router-dom";
import MetadataFileHistoryGrid from "./MetadataFileHistoryGrid";
import MetadataStatisticsGrid from "./MetadataStatisticsGrid";
import Sidepanel from "../Dashboard/Sidepanel";

const CreateButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const MetadataIngestion = () => {
    const navigate = useNavigate();

    const navigateToNewMetadataIngestion = () => {
        navigate("/data-processing/metadata-ingestion/new");
    };

    return (

        <div style={{display: "grid", gridTemplateColumns: "0fr 1fr", gap: "1rem"}}>
            <div>
                <Sidepanel/>
            </div>
            <div>
                <WppTopbar navigation={[]} style={{margin: "1rem"}}>

                    <div slot="app">
                        <WppTypography type="l-midi" style={{width: "24rem"}}>
                            Welcome to Metadata Ingestion
                        </WppTypography>

                    </div>


                </WppTopbar>

                <div style={{margin: "4rem 10rem"}}>

                    <CreateButtonContainer>
                        <WppButton onClick={navigateToNewMetadataIngestion}>
                            Start New Metadata Ingestion
                        </WppButton>
                    </CreateButtonContainer>
                    <MetadataStatisticsGrid/>
                    <MetadataFileHistoryGrid/>
                </div>
            </div>
        </div>
    );
};

export default MetadataIngestion;
