import React, { useState } from "react";
import axios from "axios";
import {
    WppTypography,
    WppCheckbox,
    WppFileUpload,
    WppIconCross
} from "@wppopen/components-library-react";
import { AppConfig } from "./../../../AppConfig";
import { useCookies } from 'react-cookie';

type Props = {
    checked: boolean;
    onChange: (value: boolean) => void;
    title: string;
    links: { label: string, link: string, context: string }[];
    checkboxText: string;
};

export const UploadFilesStep = (props: Props) => {
    const [cookies] = useCookies(['access_token_cookie', 'csrf_access_token']);
    const [errorMessage, setErrorMessage] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);

    async function handleFileUploadChange(event: any): Promise<void> {
        const formData = event.detail.value;
        let gcsFileLocation = '';
        
        props.links.forEach((item) => {
            if (item.label === "GCS Location") {
                gcsFileLocation = item.link.replace("https://console.cloud.google.com/storage/browser/", "gs://");
            }
        });

        const accessToken = localStorage.getItem('access_token');
        const uploadConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": ` ${accessToken}`
            }
        };

        try {
            if (formData.length > 20) {
                setErrorMessage('Only 20 files are allowed for upload.');
                event.target.value = null; 
                return;
            } else {
                setErrorMessage("");
            }

            const uploadedFileNames: string[] = [];

            for (let index = 0; index < formData.length; index++) {
                const item = formData[index];
                const response = await axios.post(
                    `${AppConfig.aoPortalApiUrl}/gcs/upload`, {
                        deletePreviousFiles: index === 0,
                        gcsFilePath: `${gcsFileLocation}/`,
                        file: item
                    },
                    uploadConfig
                );

                if (response.status === 200) {
                    uploadedFileNames.push(item.name);
                } else {
                    setErrorMessage(`Failed to upload ${item.name}.`);
                    break; 
                }
            }

            setUploadedFiles(prevFiles => {
                const uniqueFiles = new Set([...prevFiles, ...uploadedFileNames]);
                return Array.from(uniqueFiles);
            });

        } catch (error) {
            console.error('Error uploading files: ', error);
            setErrorMessage('Error uploading files. Please try again later.');
        }
    }

   

    return (
        <WppTypography type="l-body">
            <div style={{ marginBottom: `1rem` }}>
                <h3>{props.title}</h3>
                                <ul>
                                {props.links.map((item, index) => (
    
        <li key={index}>
            {item.context}: <a href={item.link} target="_blank" rel="noopener noreferrer">{item.label}</a>
        </li>
    
))}
                </ul>
            </div>

            <style>
      {`
       wpp-icon-cross-v2-3-0 :: part(cross-icon ){
          display: none !important;
        }
      `}
      </style>

            <WppFileUpload
                style={{ marginBottom: `80rem` }}
                acceptConfig={{
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', ],
                    'application/vnd.ms-excel': ['.xls'],
                    'text/csv': ['.csv'],
                    'application/x-spss': ['.sav'],
                    'application/json': ['.json'],
                   'application/x-yaml': ['.yaml', '.yml'],
                }}
                onWppChange={handleFileUploadChange}
                
                                size={200}
                                
                
            />
             <WppIconCross style={{ display: 'none' }} />

            

            {errorMessage && (
                <div style={{ color: 'red', marginBottom: '1rem' }}>
                    {errorMessage}
                </div>
            )}

            <div>
            <h4>Uploaded Files:</h4>
                <ul>
                    {uploadedFiles.map((fileName, index) => (
                        <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
                           
                            <span style={{ marginRight: '10px' }}>{fileName}</span>
                            <span style={{ marginRight: '10px', color: 'green' }}>✅</span>
                            
                        </li>
                    ))}
                </ul>
            </div>

            <WppCheckbox
                style={{ padding: `5px` }}
                required={true}
                checked={props.checked}
                indeterminate={false}
                labelConfig={{
                    text: props.checkboxText,
                }}
                onWppChange={(e: CustomEvent) => props.onChange(e.detail.checked)}
            />
        </WppTypography>
    );
};
