import React, { useState, useEffect, useRef } from "react";
import {
  WppButton,
  WppSpinner,
} from "@wppopen/components-library-react";

import styles from "./questionnarie.module.css";
import { AppTopbar } from "../../components/Dashboard/AppTopbar";

import { useNavigate } from "react-router-dom";
import {
  AOQuestionnaireRequest,
  AOQuestionnaireEndPoints,
} from "../../api/audience-origin-questionnaire";

import {
  QuestionnaireList,
  QuestionnaireMetaData,
  QuestionsMetaDnd,
} from "../../types";
import { CardsPanel } from "./QuestionnaireCardPanel";
import axios from "axios";
import { SearchPanel } from "./QuestionnaireSearchPanel";
import InfiniteScroll from "react-infinite-scroller";
import { Flex } from "antd";
import {
  SkeletonCardScreen,
  SkeletonRow,
} from "../QuestionComponents/ProgressLoadingScreen";
import { ErrorScreen } from "../QuestionComponents/ErrorScreen";
import {
  setBuilderValues,
  setPreviewerValues,
  setQuestionsMetadata,
} from "../../stores/questionnaireSlice";
import { useDispatch } from "react-redux";

const defaultQuestionProps = {
  data: [],
  size: 12,
  number: 1,
  total_pages: 1,
  total_elements: 1,
  number_of_elements: 0,
  first: true,
  last: false,
  empty: false,
};

type searchContextProps = {
  search_value: string;
};

export type selectedQuestionProps = {
  id: number;
  version: number;
  name: string;
};

const QuestionnaireHomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToSurveyBuilder = () => {
    dispatch(setQuestionsMetadata([] as QuestionsMetaDnd[]));
    dispatch(setPreviewerValues(null));
    dispatch(setBuilderValues(null));
    navigate("/questionnaire-design/builder");
  };
  const [isToShowBanner, setIsToShowBanner] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(12);
  const [searchContext, setSearchContext] = useState<searchContextProps>();
  const [questionList, setQuestionList] =
    useState<QuestionnaireList>(defaultQuestionProps);
  const [questionData, setQuestionData] = useState<QuestionnaireMetaData[]>([]);
  const [showCheckBoxesView, setShowCheckBoxesView] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState<
    selectedQuestionProps[]
  >([]);

  const initialRenderCount = useRef(0);

  useEffect(() => {
    if (initialRenderCount.current === 0) {
      getQuestionsList(0);
    }
    initialRenderCount.current++;
  }, []);

  const getQuestionsList = async (pageNumber) => {
    try {
      const response = await AOQuestionnaireRequest.get<QuestionnaireList>(
        `${AOQuestionnaireEndPoints.QuestionnaireList}?page=${
          pageNumber + 1
        }&size=${size}`
      );
      setQuestionList(response.data); 
      setQuestionData((prevItems) => [...prevItems, ...response.data.data]);
      setPage(pageNumber + 1);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error.message + ". " + error.response.data[0].message);
      } else {
        setError("An unexpected error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async (searchText: string) => {
    const searchDef = {
      search_value: searchText,
    };
    setSearchContext(searchDef);
    getQuestionsListWithSearch(searchDef, false, 0);
  };

  const getQuestionsListWithSearch = async (
    searchDef,
    callingFromScroll,
    pageNumber
  ) => {
    try {
      const response = await AOQuestionnaireRequest.post<QuestionnaireList>(
        `${AOQuestionnaireEndPoints.QuestionnaireList}/search?page=${
          pageNumber + 1
        }&size=${size}`,
        searchDef
      );
     
      if (callingFromScroll) {
        setQuestionList(response.data); 
        setQuestionData((prevItems) => [...prevItems, ...response.data.data]);
      } else {
        setQuestionList(response.data); 
        setQuestionData(response.data.data);
      }
      setPage(pageNumber + 1);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error.message + ". " + error.response.data[0].message);
      } else {
        setError("An unexpected error occurred");
      }
    }
  };

  const getMoreQuestionsData = () => {
    if (searchContext?.search_value) {
      getQuestionsListWithSearch(searchContext, true, page);
    } else {
      getQuestionsList(page);
    }
  };

  const compareQuestions = () => {
    setShowCheckBoxesView(!showCheckBoxesView);
    setSelectedQuestions([]);
  };

  const handleCheckboxChange = (event, question) => {
    if (event.detail.checked) {
      if (selectedQuestions.length < 2) {
        const data = [...selectedQuestions];
        data.push({
          id: question.id,
          version: question.version,
          name: question.name,
        });
        setSelectedQuestions(data);
      }
    } else {
      const data = [...selectedQuestions.filter((v) => v.id !== question.id)];
      setSelectedQuestions(data);
    }
  };
  var errorScreen = <div></div>;

  const submitCompare = async () => {
    const compareObject = {
      questionnaire_1_id: selectedQuestions[0].id,
      questionnaire_1_version: selectedQuestions[0].version,
      questionnaire_2_id: selectedQuestions[1].id,
      questionnaire_2_version: selectedQuestions[1].version,
      questionnaire_2_json: {},
    };
    try {
      const response = await AOQuestionnaireRequest.post(
        `${AOQuestionnaireEndPoints.CompareQuestionnaire}`,
        compareObject
      );
      navigate("/questionnaire-design/compare", {
        state: {
          differences: response.data,
          question1: selectedQuestions[0].name,
          question2: selectedQuestions[1].name,
        },
      });
    } catch (error) {
      console.log(error);
      setError(null); 
      setIsToShowBanner(true);
      if (axios.isAxiosError(error)) {
        setError(error.message + ". " + error.response.data[0].message);
      } else {
        setError("An unexpected error occurred");
      }
    }
  };

  if (isLoading) {
    return (
      <div>
        <SkeletonCardScreen></SkeletonCardScreen>
      </div>
    );
  }
  if (error) {
    errorScreen = (
      <div>
        <ErrorScreen
          errorMessage={error}
          isToShowBanner={isToShowBanner}
          setIsToShowBanner={setIsToShowBanner}
        ></ErrorScreen>
      </div>
    );
  }

  return (
    <div>
      <AppTopbar></AppTopbar>
      
      <div>
        <div className={styles["error-banner"]}>{errorScreen}</div>
        <div className={styles["header"]}>
          <WppButton
            className="wpp-spacing-12-right"
            onClick={navigateToSurveyBuilder}
          >
            Start New Questionnaire
          </WppButton>

          <div>
            <WppButton
                className={showCheckBoxesView ? "wpp-spacing-12-right" : "wpp-spacing-48-right"}
              onClick={compareQuestions}
              variant="secondary"
            >
              {showCheckBoxesView ? "Reset" : "Compare"}
            </WppButton>
            {showCheckBoxesView ? (
              <WppButton
                className="wpp-spacing-48-right wpp-margin-top-1"
                onClick={submitCompare}
                variant="secondary"
              >
                Submit
              </WppButton>
            ) : null}
          </div>
        </div>

        <Flex className={styles["search-header"]} justify="space-between">
          <SearchPanel 
          handleSearch={handleSearch}
          value={searchTerm}
          setSearchTerm={setSearchTerm}
           />

        </Flex>
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={getMoreQuestionsData}
          hasMore={questionData.length < questionList.total_elements}
          loader={
            <div className="loader" key={0}>
              <SkeletonRow></SkeletonRow>
            </div>
          }
        >
          <CardsPanel
            selectedQuestions={selectedQuestions}
            questionList={questionData}
            showCheckBoxesView={showCheckBoxesView}
            handleCheckboxChange={handleCheckboxChange}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default QuestionnaireHomePage;
