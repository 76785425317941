import React, { Component } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import {AppConfig} from "../../AppConfig";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2, 3, 0, 3),
  display: "flex",
  overflow: "auto",
  flexDirection: "column",
}));

class AllureDockerErrorPage extends Component {
  render() {
    let connectivityIssue = "";
    const errorMessage = this.props.error.message;
    if (errorMessage) {
      if (errorMessage.includes("Network Error")) {
        connectivityIssue = (
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              Check if you have access to{" "}
              <Link
                color="secondary"
                href={AppConfig.alluerDockerApiUrl}
                rel="noopener noreferrer"
                target="_blank"
                underline="hover"
              >
                {AppConfig.alluerDockerApiUrl}
              </Link>
            </Typography>
           
          </React.Fragment>
        );
      }
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <StyledPaper>
              <Typography variant="h1" gutterBottom align="center">
                {this.props.error.status}
                {process.env.REACT_APP_ALLURE_DOCKER_PUBLIC_API_URL_PREFIX}
              </Typography>
              <Typography variant="h3" gutterBottom>
                {this.props.error.message}
              </Typography>
              {connectivityIssue}
              <Typography
                variant="body1"
                gutterBottom
                paragraph
                align="justify"
              >
                {this.props.error.stackTrace}
              </Typography>
            </StyledPaper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
export default AllureDockerErrorPage;
