import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import "@wppopen/components-library/dist/platform-ui-kit/global.css";
import "@wppopen/components-library/dist/platform-ui-kit/wpp-theme.css";
import "@wppopen/components-library/dist/collection/ag-theme-wpp.css";
import "ag-grid-community/styles/ag-grid.css";
import "./index.css";
import "./ag-theme-custom.css";
import "./AgGridTable.scss";
import { Provider } from "react-redux";
import { store } from "./stores";
import { ErrorProvider } from "./providers/ErrorContext";
const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

// root.render(<App />);
root.render(
  <ErrorProvider>
  <React.StrictMode>
    
    <Provider store={store}>
    
      <App />
      
    </Provider>
  </React.StrictMode>
  </ErrorProvider>
);
