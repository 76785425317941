import React from "react";
import { Link } from "react-router-dom";
import {
  WppCard,
  WppActionButton,
  WppGrid,
  WppIconMore,
  WppTypography,
} from "@wppopen/components-library-react";
import DashboardCard from "./DashboardCard";
import { DASHBOARD_CARDS } from "../../constants/dashboard-cards";

const Dashboard = () => {
  return (
    <WppGrid container justifyContent="space-between">
      {DASHBOARD_CARDS.map((card) => (
        <WppGrid item all={7} key={card.title}>
          <DashboardCard {...card} />
        </WppGrid>
      ))}
    </WppGrid>
  );
};

export default Dashboard;
