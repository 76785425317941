import React, { useState } from "react";
import styles from "./QuestionnaireBuilder.module.css";
import {
  WppTabs,
  WppTab,
  WppSideModal,
  WppButton,
} from "@wppopen/components-library-react";
import { OutLineList } from "./OutLineList";
import { DraggableQuestionIcon } from "../QuestionComponents/DraggableQuestionIcon";
import {
  SkeletonCardScreen,
  SkeletonRow,
} from "../QuestionComponents/ProgressLoadingScreen";
import { CardsPanel } from "../QuestionnaireHome/QuestionnaireCardPanel";
import { SearchPanel } from "../QuestionnaireHome/QuestionnaireSearchPanel";
import { Flex } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import {
  AOQuestionnaireEndPoints,
  AOQuestionnaireRequest,
} from "../../api/audience-origin-questionnaire";
import {
  SemanticSearchEndPoints,
  SemanticSearchRequest,
} from "../../api/audience-origin-semantics";
import {
  QuestionnaireList,
  QuestionnaireMetaData,
  QuestionsMetaDnd,
  SemanticSearchList,
} from "../../types";
import axios from "axios";
import { Hidden, Select } from "@mui/material";
import { DraggableSearchQuestionIcon } from "../QuestionComponents/DraggableSearchQuestionIcon";
import { QuestionareSelect } from "../QuestionnaireHome/QuestionareSelect";
import { SearchCardsPanel } from "../QuestionnaireHome/SearchCardPanel";

type ListElementProps = {
  result: any;
};

type SearchPanelProps = {
  showPanel: boolean;
  handleOpenModal: any;
  handleCloseModal: any;
  handleCheckboxChange: (event, question: any[]) => void;
  IsChecked: (question: any[]) => boolean;
  handleAdd: () => void;
  value:any;
  setSearchTerm:any;
  setShowResultData:any;
  showResultData:any;
};

type selectedQuestionProps = {
  id: number;
  version: number;
  name: string;
};

const defaultQuestionProps = {
  data: [],
};
type searchContextProps = {
  search_value: string;
};

const SearchQuestionPanel: React.FC<SearchPanelProps> = (props) => {

  const [dropdownTerm, setDropdownTerm] = useState("search_term");
  const [searchContext, setSearchContext] = useState<searchContextProps>();
  const [size, setSize] = useState<number>(12);
  const [error, setError] = useState<string | null>(null);
  const [questionList, setQuestionList] = useState<SemanticSearchList>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [questionData, setQuestionData] = useState<QuestionnaireMetaData[]>([]);
  const [page, setPage] = useState<number>(1);
  //const [showResultData, setShowResultData] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState<any[]>([]);
  
  const getQuestionsList = async (pageNumber) => {
    try {
      const response = await AOQuestionnaireRequest.get<QuestionnaireList>(
        `${AOQuestionnaireEndPoints.QuestionnaireList}?page=${
          pageNumber + 1
        }&size=${size}`
      );

      setQuestionData((prevItems) => [...prevItems, ...response.data.data]);
      setPage(pageNumber + 1);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle the axios error here
        setError(error.message);
      } else {
        // Handle unexpected errors
        setError("An unexpected error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getQuestionsListWithSearch = async (params) => {
    try {
      const response = await SemanticSearchRequest.post<SemanticSearchList>(
        `${SemanticSearchEndPoints.Search}`,
        params
      );
      console.log(response);
      setQuestionList(response.data);
      
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle the axios error here
        setError(error.message);
      } else {
        // Handle unexpected errors
        setError("An unexpected error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  

  const handleChange = (event: CustomEvent) => {
    console.log("value info :>> ", event.detail.value);
    setDropdownTerm(event.detail.value);
  };
  const handleCheckboxChange = (event, question) => {
    //setSelectedQuestions(question);
    console.log(question);
  };
  const [showSearchPanel, setshowSearchPanel] = useState(false);
  const handleOpenSearchModal = () => setshowSearchPanel(true);
  const handleCloseSearchModal = () => {
    setshowSearchPanel(false)
  
  };

  const handleSearch = async (searchText: string) => {
    const searchDef = {
      search_value: searchText,
    };
    const params = {
      question_label: "",
      search_term: '',
      survey_type: "",
    };
    props.setShowResultData(false);
    if (searchText != null && searchText != "") {
      if (dropdownTerm == "Label") {
        params.question_label = searchText;
      } else {
        params.search_term = searchText;
      }
      //setSearchContext(searchDef);
     props. setShowResultData(true);
      // On serach page number should be first index
      getQuestionsListWithSearch(params);
    }else{
     props. setShowResultData(false);
    }
    
  };

  let display = props.showResultData ? "inline" : "none";
  let households = ["0-1", "1-2", "3+"];
  let goodsQuestion = ["Baby Food", "Diaper", "Milk"];
  let goodAnswers = ["Once a week", "once a month", "Once a year"];
  function AddFunction(): void {
    throw new Error("Function not implemented.");
  }
  

  return (
    <WppSideModal
      open={props.showPanel}
      onWppSideModalClose={props.handleCloseModal}
      onWppSideModalOpen={props.handleOpenModal}
      className={styles["question-modal"]}
    >
      <div slot="body">
        <div style={{ display: "inline-flex" }}>
          <QuestionareSelect handleChange={handleChange} />
          <SearchPanel 
          handleSearch={handleSearch}
          value={props.value}
          setSearchTerm={props.setSearchTerm}
           />
        </div>
        {questionList && questionList.length ? (
          questionList.map((question, index) => (
            <div key={index} style={{ display: display }}>
              <SearchCardsPanel
                IsChecked={props.IsChecked}
                handleCheckboxChange={props.handleCheckboxChange}
                type={question.type}
                selectedQuestion={question.question_text}
                questionCode={[]}
                question={question}
                code={question.question_label}
                answerCode={question.answers}
                showCheckBoxesView={true}
              />
            </div>
          ))
        ) : (
          <div></div>
        )}
        <div>
          <WppButton className={styles["add-bottom"]} onClick={props.handleAdd}>
            Add
          </WppButton>
        </div>
      </div>
    </WppSideModal>
  );
};

export default SearchQuestionPanel;
